import { TermValue } from "@/models/ItemEntity";

export type GeoField = "city" | "country" | "lat_long_list" | "region";

export class GeoTerm {
  city: TermValue | null;
  country: TermValue | null;
  lat_long_list: TermValue | null;
  region: TermValue | null;

  constructor(payload?: Partial<GeoTerm>) {
    this.city = payload?.city ?? null;
    this.country = payload?.country ?? null;
    this.lat_long_list = payload?.lat_long_list ?? null;
    this.region = payload?.region ?? null;
  }

  hasField(field: GeoField): boolean {
    return this[field] !== null;
  }

  getField(field: GeoField): TermValue | null {
    return this[field];
  }

  setField(field: GeoField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class GeoResource {
  city: TermValue[];
  country: TermValue[];
  lat_long_list: TermValue[];
  region: TermValue[];

  constructor(payload?: Partial<GeoResource>) {
    this.city = payload?.city ?? [];
    this.country = payload?.country ?? [];
    this.lat_long_list = payload?.lat_long_list ?? [];
    this.region = payload?.region ?? [];
  }

  getResource(field: GeoField): TermValue[] {
    return this[field];
  }

  setResource(field: GeoField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  { id: "1", value: "Item Debug 1" },
  { id: "2", value: "Item Debug 2" },
];
