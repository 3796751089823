import { AppSiteField } from "@/models/Modifiers/Terms/app_site";
import { ContentField } from "@/models/Modifiers/Terms/content";
import { EnvironmentField } from "@/models/Modifiers/Terms/eenvironment";
import { ExchangeField } from "@/models/Modifiers/Terms/exchange";
import { GeoField } from "@/models/Modifiers/Terms/geo";
import { VideoField } from "@/models/Modifiers/Terms/video";
import { PlatformField } from "@/models/Modifiers/Terms/platform";
import { ModifierOptionKey } from "@/models/Modifiers/Terms";

export type ComponentType = "searcher" | "autocomplete" | "text-field" | "radio-button";

export type FieldAsKeyItem =
  | "external_id"
  | "extra"
  | "name"
  | "app_name"
  | "key"
  | "site_id"
  | "prefix"
  | "prefix_3"
  | "string";

export type DeliverModifierField =
  | AppSiteField
  | ContentField
  | EnvironmentField
  | ExchangeField
  | GeoField
  | PlatformField
  | VideoField;

export class ConfigModifierTerm {
  model: DeliverModifierField;
  placeholder: string;
  resource: string;
  disabled: boolean;
  text: string;
  value: string;
  moduleName: ModifierOptionKey;
  componentType: ComponentType;

  constructor(data: any) {
    this.model = data.model;
    this.placeholder = data.placeholder;
    this.resource = data.resource;
    this.disabled = data.disabled ?? false;
    this.text = data.text;
    this.value = data.value;
    this.moduleName = data.module_name;
    this.componentType = data.component_type || "text-field";
  }

  isComponentType(type: ComponentType) {
    return this.componentType === type;
  }
}

class ConfigModifier {
  terms: ConfigModifierTerm[] = [];

  constructor(configData: Record<string, any>) {
    this.terms = Object.entries(configData).map(([_, data]) => new ConfigModifierTerm(data));
  }

  getConfig(target: DeliverModifierField) {
    return this.terms.find(t => t.model === target);
  }
}

const configData = {
  // app_site
  app_bundle_list: {
    model: "app_bundle_list",
    placeholder: "Any Value",
    resource: "app_bundle_list",
    text: "name",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  app_name: {
    model: "app_name",
    placeholder: "Any Value",
    resource: "app_name",
    text: "app_name",
    value: "app_name",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "searcher",
  },
  app_id_list: {
    model: "app_id_list",
    placeholder: "Any Value",
    resource: "app_id_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  deal_id_list: {
    model: "deal_id_list",
    placeholder: "Any Value",
    resource: "deal_id_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  placement_id: {
    model: "placement_id",
    placeholder: "Any Value",
    resource: "placement_id",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "text-field",
  },
  placement_list: {
    model: "placement_list",
    placeholder: "Any Value",
    resource: "placement_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  publisher_id: {
    model: "publisher_id",
    placeholder: "Any Value",
    resource: "publisher_id",
    text: "",
    value: "",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "text-field",
  },
  publisher_id_list: {
    model: "publisher_id_list",
    placeholder: "Any Value",
    resource: "publisher_id_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  site: {
    model: "site",
    placeholder: "Any Value",
    resource: "site",
    text: "site_id",
    value: "site_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "searcher",
  },
  site_list: {
    model: "site_list",
    placeholder: "Any Value",
    resource: "site_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  deal_id: {
    model: "deal_id",
    placeholder: "Any Value",
    resource: "deal_id",
    text: "",
    value: "",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "text-field",
  },
  domain_list: {
    model: "domain_list",
    placeholder: "Any Value",
    resource: "domain_list",
    text: "description",
    value: "external_id",
    module_name: "app_site" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // content
  content_rating: {
    model: "content_rating",
    placeholder: "Any Value",
    resource: "content_rating",
    text: "description",
    value: "extra",
    module_name: "content" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  language: {
    model: "language",
    placeholder: "Any Value",
    resource: "language",
    text: "description",
    value: "extra",
    module_name: "content" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // environment
  ad_size: {
    model: "ad_size",
    placeholder: "Any Value",
    resource: "ad_size",
    text: "name",
    value: "id",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  ad_position: {
    model: "ad_position",
    placeholder: "Any Value",
    resource: "ad_position",
    text: "description",
    value: "external_id",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  environment_type: {
    model: "environment_type",
    placeholder: "Any Value",
    resource: "environment_type",
    text: "description",
    value: "extra",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  interstitial: {
    model: "interstitial",
    placeholder: "Any Value",
    resource: "interstitial_flags",
    text: "description",
    value: "extra",
    module_name: "environment" as ModifierOptionKey,
    component_type: "radio-button",
  },
  interstitial_type: {
    model: "interstitial_type",
    placeholder: "Any Value",
    resource: "interstitial_type",
    text: "value",
    value: "key",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  topframe: {
    model: "topframe",
    placeholder: "Any Value",
    resource: "topframe",
    text: "description",
    value: "id",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  video_api: {
    model: "video_api",
    placeholder: "Any Value",
    resource: "video_api",
    text: "description",
    value: "extra",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  rewarded: {
    model: "rewarded",
    placeholder: "Any Value",
    resource: "rewarded",
    text: "description",
    value: "extra",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  native_layout: {
    model: "native_layout",
    placeholder: "Any Value",
    resource: "native_layout",
    text: "description",
    value: "extra",
    module_name: "environment" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // exchange
  auction_type: {
    model: "auction_type",
    placeholder: "Any Value",
    resource: "auction_type",
    text: "description",
    value: "extra",
    module_name: "exchange" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  inventory_source: {
    model: "inventory_source",
    placeholder: "Any Value",
    resource: "inventory_source",
    text: "name",
    value: "key",
    module_name: "exchange" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // geo
  city: {
    model: "city",
    placeholder: "Any Value",
    resource: "city",
    text: "description",
    value: "prefix",
    module_name: "geo" as ModifierOptionKey,
    component_type: "searcher" as ComponentType,
  },
  country: {
    model: "country",
    placeholder: "Any Value",
    resource: "country",
    text: "description",
    value: "prefix_3",
    module_name: "geo" as ModifierOptionKey,
    component_type: "searcher" as ComponentType,
  },
  region: {
    model: "region",
    placeholder: "Any Value",
    resource: "region",
    text: "description",
    value: "key",
    module_name: "geo" as ModifierOptionKey,
    component_type: "searcher" as ComponentType,
  },
  lat_long_list: {
    model: "lat_long_list",
    placeholder: "Any Value",
    resource: "lat_long_list",
    text: "name",
    value: "external_id",
    module_name: "geo" as ModifierOptionKey,
    component_type: "autocomplete" as ComponentType,
  },

  // platform
  browser: {
    model: "browser",
    placeholder: "Any Value",
    resource: "browser",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  browser_version: {
    model: "browser_version",
    placeholder: "Any Value",
    resource: "browser_version",
    text: "description",
    value: "key",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  device_make: {
    model: "device_make",
    placeholder: "Any Value",
    resource: "device_make",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  device_model: {
    model: "device_model",
    placeholder: "Any Value",
    resource: "device_model",
    text: "description",
    value: "key",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  device_screen_size: {
    model: "device_screen_size",
    placeholder: "Any Value",
    resource: "device_screen_size",
    text: "description",
    value: "key",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  device_type: {
    model: "device_type",
    placeholder: "Any Value",
    resource: "device_type",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  operating_system: {
    model: "operating_system",
    placeholder: "Any Value",
    resource: "operating_system",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  operating_system_version: {
    model: "operating_system_version",
    placeholder: "Any Value",
    resource: "operating_system_version",
    text: "description",
    value: "key",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  bandwidth: {
    model: "bandwidth",
    placeholder: "Any Value",
    resource: "bandwidth",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  carrier: {
    model: "carrier",
    placeholder: "Any Value",
    resource: "carrier",
    text: "description",
    value: "extra",
    module_name: "platform" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // video
  companion_required: {
    model: "companion_required",
    placeholder: "Any Value",
    resource: "companion_required",
    text: "description",
    value: "extra",
    module_name: "video" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  playback_method: {
    model: "playback_method",
    placeholder: "Any Value",
    resource: "playback_method",
    text: "description",
    value: "extra",
    module_name: "video" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  player_size: {
    model: "player_size",
    placeholder: "Any Value",
    resource: "player_size",
    text: "description",
    value: "extra",
    module_name: "video" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  start_delay: {
    model: "start_delay",
    placeholder: "Any Value",
    resource: "start_delay",
    text: "description",
    value: "extra",
    module_name: "video" as ModifierOptionKey,
    component_type: "autocomplete",
  },
  video_placement_type: {
    model: "video_placement_type",
    placeholder: "Any Value",
    resource: "video_placement_type",
    text: "description",
    value: "extra",
    module_name: "video" as ModifierOptionKey,
    component_type: "autocomplete",
  },

  // time
  user_time_of_week: {
    model: "user_time_of_week",
    placeholder: "Any Value",
    resource: "user_time_of_week",
    text: "",
    value: "",
    module_name: "time" as ModifierOptionKey,
    component_type: "text-field" as ComponentType,
    disabled: true,
  },
};

export const configModifier = new ConfigModifier(configData);
