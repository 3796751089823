import { TermValue } from "@/models/ItemEntity";

export type AudioField = "audio_start_delay";

export class AudioTerm {
  audio_start_delay: TermValue | null;

  constructor(payload?: Partial<AudioTerm>) {
    this.audio_start_delay = payload?.audio_start_delay ?? null;
  }

  hasField(field: AudioField): boolean {
    return this[field] !== null;
  }

  getField(field: AudioField): TermValue | null {
    return this[field];
  }

  setField(field: AudioField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class AudioResource {
  audio_start_delay: TermValue[];

  constructor(payload?: Partial<AudioResource>) {
    this.audio_start_delay = payload?.audio_start_delay ?? [];
  }

  getResource(field: AudioField): TermValue[] {
    return this[field];
  }

  setResource(field: AudioField, values: TermValue[]): void {
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

export class AudioLoading {
  audio_start_delay: boolean;

  constructor(payload?: Partial<AudioLoading>) {
    this.audio_start_delay = payload?.audio_start_delay ?? false;
  }

  isLoading(field: AudioField): boolean {
    return this[field];
  }

  setLoading(field: AudioField, value: boolean): void {
    this[field] = value;
  }
}
