import { TermValue } from "@/models/ItemEntity";

export type VideoField =
  | "companion_required"
  | "playback_method"
  | "player_size"
  | "start_delay"
  | "video_placement_type";

export class VideoTerm {
  companion_required: TermValue | null;
  playback_method: TermValue | null;
  player_size: TermValue | null;
  start_delay: TermValue | null;
  video_placement_type: TermValue | null;

  constructor(payload?: Partial<VideoTerm>) {
    this.companion_required = payload?.companion_required ?? null;
    this.playback_method = payload?.playback_method ?? null;
    this.player_size = payload?.player_size ?? null;
    this.start_delay = payload?.start_delay ?? null;
    this.video_placement_type = payload?.video_placement_type ?? null;
  }

  hasField(field: VideoField): boolean {
    return this[field] !== null;
  }

  getField(field: VideoField): TermValue | null {
    return this[field];
  }

  setField(field: VideoField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class VideoResource {
  companion_required: TermValue[];
  playback_method: TermValue[];
  player_size: TermValue[];
  start_delay: TermValue[];
  video_placement_type: TermValue[];

  constructor(payload?: Partial<VideoResource>) {
    this.companion_required = payload?.companion_required ?? [];
    this.playback_method = payload?.playback_method ?? [];
    this.player_size = payload?.player_size ?? [];
    this.start_delay = payload?.start_delay ?? [];
    this.video_placement_type = payload?.video_placement_type ?? [];
  }

  getResource(field: VideoField): TermValue[] {
    return this[field];
  }

  setResource(field: VideoField, values: TermValue[]): void {
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

export class VideoLoading {
  companion_required: boolean;
  playback_method: boolean;
  player_size: boolean;
  start_delay: boolean;
  video_placement_type: boolean;

  constructor(payload?: Partial<VideoLoading>) {
    this.companion_required = payload?.companion_required ?? false;
    this.playback_method = payload?.playback_method ?? false;
    this.player_size = payload?.player_size ?? false;
    this.start_delay = payload?.start_delay ?? false;
    this.video_placement_type = payload?.video_placement_type ?? false;
  }

  isLoading(field: VideoField): boolean {
    return this[field];
  }

  setLoading(field: VideoField, value: boolean): void {
    this[field] = value;
  }
}
