import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";

import { ConfigModifierTerm, DeliverModifierField } from "@/models/Modifiers/Delivery/term";
import { ModifierOptionKey } from "@/models/Modifiers/Terms";
import { TermValue } from "@/models/ItemEntity";
import { FieldMapItem } from "@/mixins/modifiers/TermModule";
import RadioButton from "@/components/Content/RadioButton.vue";

@Component({
  components: { RadioButton },
  methods: {
    ...mapActions("targeting", {
      fetchTargetingKey: "getTargetingKey",
    }),
  },
})
export default class SelectorRadioButton extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) moduleKey!: ModifierOptionKey;
  @Prop({ required: true }) termKey!: DeliverModifierField;
  @Prop({ required: true }) config!: ConfigModifierTerm;
  @Prop({ default: false }) disabled!: boolean;

  items: any[] = [];
  isLoading = false;

  created() {
    this.$nextTick(() => {
      // console.debug(`[SelectorRadioButton::nextTick] moduleKey: ${this.moduleKey} termKey: ${this.termKey}`, { value: this.value });
      this.fetchData(this.termKey);
    });
  }

  /**
   * Se activa cuando se ejecuta el evento: focus en el campo `key`
   * @param key
   */
  async fetchData(key: DeliverModifierField) {
    try {
      const resource: TermValue[] = await this.fetchTargetingByKey(key);
      this.items = resource;
    } catch (error) {
      console.error(`[Autocomplete::fetchData] key: ${key}`, { error });
      this.items = [];
    } finally {
    }
  }

  async fetchTargetingByKey(key: string): Promise<TermValue[]> {
    try {
      return await this.fetchTargetingKey({ key, object: await this.getFieldByKey() });
    } catch (error) {
      console.error(`[Autocomplete] fetchTargetingByKey - key: ${key}`, error);
      return [];
    }
  }

  /**
   * Fetch de datos [mapActions]
   * @param key
   * @returns
   */
  public fetchTargetingKey!: (payload: { key: string; object?: FieldMapItem }) => Promise<TermValue[]>;

  async getFieldByKey(): Promise<FieldMapItem> {
    return { key: this.config.value, value: this.config.text };
  }

  @Watch("value", { immediate: true, deep: true })
  private onValueChanged(newValue: any, oldValue: any): void {
    if(!Object.keys(newValue).length || !newValue.value) return;
    this.value.value_name = newValue.value;
  }
}
