import {
  hasMaxTwoDecimals,
  isAfterCompare,
  isBetween,
  isMax,
  isMaxCustomLength,
  isMin,
  isRequired,
} from "@/services/rule-services";

export class LineItemRule {
  campaign_id: any[] = [];
  advertiser_id: any[] = [];
  start_date: any[] = [];
  end_date: any[] = [];
  retailer_id: any[] = [];
  line_item_type_id: any[] = [];
  name: any[] = [];
  creative_method_id: any[] = [];
  line_duration: any[] = [];
  budget_type_id: any[] = [];
  budget: any[] = [];
  strategy_id: any[] = [];
  bid_strategy_id: any[] = [];
  fix_cpm: any[] = [];
  cpm_bid: any[] = [];
  max_cpm_bid: any[] = [];
  target_ecpc: any[] = [];
  expected_ctr: any[] = [];
  target_ecpm: any[] = [];
  target_vcr: any[] = [];
  line_pacing_id: any[] = [];
  daily_budget: any[] = [];
  pacing_behavior_id: any[] = [];
  catchup_behavior_id: any[] = [];
  multiplier_pacing_behavior: any[] = [];

  constructor() {}

  addCommonRules() {
    this.campaign_id = [isRequired];
    this.advertiser_id = [isRequired];
    this.line_item_type_id = [isRequired];
    this.name = [isRequired];
    this.creative_method_id = [isRequired];
    this.budget_type_id = [isRequired];
    this.strategy_id = [isRequired];
    this.bid_strategy_id = [isRequired];
    this.cpm_bid = [isRequired];
    this.max_cpm_bid = [isRequired];
    this.expected_ctr = [isRequired];
    this.target_ecpm = [isRequired];
    this.line_pacing_id = [isRequired];
    this.daily_budget = [isRequired];
    this.pacing_behavior_id = [isRequired];
    this.catchup_behavior_id = [isRequired];
  }

  addNameRules(name: string) {
    this.name = [isRequired, isMaxCustomLength(name, 191)];
  }

  addRetailerRules(required: boolean) {
    this.retailer_id = required ? [isRequired] : [];
  }

  addDateRules(startDate: Date | null, endDate: Date | null) {
    this.start_date = [isRequired];
    this.end_date = [isRequired, isAfterCompare(endDate, startDate)];
    this.line_duration = [isRequired];
  }

  addBudgetRules(lineBudget: number | null, campaignBudget: number | undefined) {
    this.budget = [isRequired, isMax(lineBudget, campaignBudget)];
  }

  addFixCpmRules(fixCpm: number) {
    this.fix_cpm = [isRequired, isMin(fixCpm, 0)];
  }

  addTargetEcpmRules(required: boolean) {
    this.target_ecpm = required ? [isRequired] : [];
  }

  addFixCtrRules(required: boolean, expectedCtr: number) {
    this.expected_ctr = required ? [isRequired, isMax(expectedCtr, 100)] : [];
  }

  addTargetVcrRules(required: boolean, targetVcr: number) {
    this.target_vcr = required ? [isRequired, isMax(targetVcr, 100), isMin(targetVcr, 1)] : [];
  }

  addMultiplierPacingBehabiourRules(multiplierPacingBehabiour: number) {
    this.target_vcr = [
      isRequired,
      isBetween(multiplierPacingBehabiour, { min: 1, max: 2 }),
      hasMaxTwoDecimals(multiplierPacingBehabiour),
    ];
  }
}
