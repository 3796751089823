
import Vue from "vue";

import AppSiteModule from "@/views/Admin/Modifiers/Modules/AppSiteModule.vue";
import ContentModule from "@/views/Admin/Modifiers/Modules/ContentModule.vue";
import EnvironmentModule from "@/views/Admin/Modifiers/Modules/EnviromentModule.vue";
import ExchangeModule from "@/views/Admin/Modifiers/Modules/ExchangeModule.vue";
import GeoModule from "@/views/Admin/Modifiers/Modules/GeoModule.vue";
import VideoModule from "@/views/Admin/Modifiers/Modules/VideoModule.vue";
import AudioModule from "@/views/Admin/Modifiers/Modules/AudioModule.vue";
import TimeModule from "@/views/Admin/Modifiers/Modules/TimeModule.vue";
import PlatformModule from "@/views/Admin/Modifiers/Modules/PlatformModule.vue";
import UserModule from "@/views/Admin/Modifiers/Modules/UserModule.vue";
import { isModuleInDeliveryModifier, ModifierModule } from "@/services/Modifiers/types";

export default Vue.extend({
  name: "PanelOptions",
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    modifier_type: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
    matching_types: {
      type: Array,
      required: true,
    },
    modifiersOptions: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  components: {
    GeoModule,
    ContentModule,
    VideoModule,
    AudioModule,
    TimeModule,
    PlatformModule,
    UserModule,
    AppSiteModule,
    ExchangeModule,
    EnvironmentModule,
  },

  data: () => ({}),

  created() {},

  async mounted() {},

  computed: {
    getTitle(): String {
      const moduleType = this.module?.extra ?? "select-module";
      return this.$t(`modifier.labels.panel-options.title.${moduleType}`);
    },
  },
  methods: {
    isModule(moduleExtra: ModifierModule) {
      return this.module?.extra === moduleExtra;
    },

    isVisibleTerm(moduleExtra: ModifierModule): boolean {
      return isModuleInDeliveryModifier(this.modifier_type?.value ?? "Bid Modifier", moduleExtra);
    },

    getModifierOption(key: string) {
      return this.modifiersOptions[key];
    },

    handleAddTerm(item: any) {
      this.$emit("add-term", { ...item, actions: undefined });
    },

    handleRemoveTermTime(item: any) {
      this.$emit("remove-term-time", item);
    },

    removeTermTime(item: any) {
      this.$refs.moduleTime.removeTermTime(item);
    },

    handleGetTerm(term_name: any) {
      this.$emit("get-term", term_name);
    },

    setTerm(valuesTerm: any, term: any) {
      switch (term) {
        case "user_time_of_week":
          this.$refs.moduleTime.setTerm(valuesTerm);
          break;
        case "interstitial":
          this.$refs.moduleEnvironment.setTerm(valuesTerm);
          break;
      }
    },
  },
  watch: {},
});
