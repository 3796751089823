export class PayloadTerm {
  public key: string;
    public value: string;
    public module_id: number;
    public matching_type_id: number;
    public modifier: string;
    public override_multiplier?: 0|1;

  constructor(payload: PayloadTerm) {
    this.key = payload.key;
    this.value = payload.value;
    this.module_id = payload.module_id;
    this.matching_type_id = payload.matching_type_id;
    this.modifier = payload.modifier;
    this.override_multiplier = payload.override_multiplier;
  }
}

export class PayloadModifier {
  constructor(
    public name: string,
    public advertiser_id: number,
    public modifier_type_id: number,
    public module_id: number,
    public bid_model_id: number | undefined,
    public alternative_id: number | undefined,
    public active: boolean,
    public terms: PayloadTerm[],
  ) {}
}

export class PayloadModifierBuilder {
  private name!: string;
  private advertiser_id!: number;
  private modifier_type_id!: number;
  private module_id!: number;
  private bid_model_id!: number | undefined;
  private alternative_id!: number | undefined;
  private active!: boolean;
  private terms: PayloadTerm[] = [];

  constructor(json: any) {
    this.name = json.name;
    this.advertiser_id = json.advertiser_id;
    this.modifier_type_id = json.modifier_type_id;
    this.module_id = json.module_id;
    this.bid_model_id = json.bid_model_id ?? undefined;
    this.alternative_id = json.alternative_id ? Number(json.alternative_id) : undefined;
    this.active = json.active;
    this.terms = json.terms.map(
      (term: any) => new PayloadTerm(term),
    );
  }

  build(): PayloadModifier {
    return new PayloadModifier(
      this.name,
      this.advertiser_id,
      this.modifier_type_id,
      this.module_id,
      this.bid_model_id,
      this.alternative_id,
      this.active,
      this.terms,
    );
  }
}
