/**
 * Ordena un array de objetos en función del orden definido en un array clave.
 *
 * @param items - El array de objetos a ordenar.
 * @param keyOrder - Un array de strings que define el orden deseado.
 * @param key - La clave del objeto que se usará para la comparación.
 * @returns Un nuevo array ordenado según el orden especificado en keyOrder.
 */
export function sortByCustomOrder<T>(items: T[], keyOrder: string[], key: keyof T): T[] {
  const orderMap = new Map(keyOrder.map((value, index) => [value, index]));

  return [...items].sort((a, b) => {
    const indexA = orderMap.get(a[key] as unknown as string) ?? Infinity;
    const indexB = orderMap.get(b[key] as unknown as string) ?? Infinity;
    return indexA - indexB;
  });
}
