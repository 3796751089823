import { TermValue } from "@/models/ItemEntity";

export type EnvironmentField =
  | "interstitial"
  | "interstitial_type"
  | "video_api"
  | "topframe"
  | "ad_size"
  | "ad_position"
  | "environment_type"
  | "rewarded"
  | "native_layout";

// ad_position ad_size environment_type interstitial interstitial_type
// video_api topframe

export class EnvironmentTerm {
  interstitial: string | null;
  interstitial_type: TermValue | null;
  video_api: TermValue | null;
  topframe: TermValue | null;
  ad_size: TermValue | null;
  ad_position: TermValue | null;
  environment_type: TermValue | null;
  rewarded: TermValue | null;
  native_layout: TermValue | null;

  constructor(payload?: Partial<EnvironmentTerm>) {
    this.interstitial = payload?.interstitial ?? "";
    this.interstitial_type = payload?.interstitial_type ?? null;
    this.video_api = payload?.video_api ?? null;
    this.topframe = payload?.topframe ?? null;
    this.ad_size = payload?.ad_size ?? null;
    this.ad_position = payload?.ad_position ?? null;
    this.environment_type = payload?.environment_type ?? null;
    this.rewarded = payload?.rewarded ?? null;
    this.native_layout = payload?.native_layout ?? null;
  }

  hasField(field: EnvironmentField): boolean {
    return this[field] !== null;
  }

  getField(field: EnvironmentField): TermValue | null {
    return this[field] as TermValue;
  }

  setField(field: EnvironmentField, value: TermValue | null | string): void {
    if (field === "interstitial") {
      this.interstitial = typeof value === "string" ? value : null;
    } else {
      const term = value as TermValue;
      this[field] = value ? new TermValue(term.id, term.value) : null;
    }
  }
}

export class EnvironmentResource {
  interstitial: TermValue[];
  interstitial_type: TermValue[];
  video_api: TermValue[];
  topframe: TermValue[];
  ad_size: TermValue[];
  ad_position: TermValue[];
  environment_type: TermValue[];
  rewarded: TermValue[];
  native_layout: TermValue[];

  constructor(payload?: Partial<EnvironmentResource>) {
    this.interstitial = payload?.interstitial ?? [];
    this.interstitial_type = payload?.interstitial_type ?? [];
    this.video_api = payload?.video_api ?? [];
    this.topframe = payload?.topframe ?? [];
    this.ad_size = payload?.ad_size ?? [];
    this.ad_position = payload?.ad_position ?? [];
    this.environment_type = payload?.environment_type ?? [];
    this.rewarded = payload?.rewarded ?? [];
    this.native_layout = payload?.native_layout ?? [];
  }

  getResource(field: EnvironmentField): TermValue[] {
    return this[field];
  }

  setResource(field: EnvironmentField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  { id: "1", value: "Item Debug 1" },
  { id: "2", value: "Item Debug 2" },
];
