export class TermValue {
  value: any[];
  targeting_terms: any[];
  targeting_key_id: number;
  targeting_predicate_id: number;
  unique: boolean;

  constructor(payload?: Partial<TermValue>) {
    this.value = payload?.value ?? [];
    this.targeting_terms = payload?.targeting_terms ?? [];
    this.targeting_key_id = payload?.targeting_key_id ?? 31;
    this.targeting_predicate_id = payload?.targeting_predicate_id ?? NaN;
    this.unique = payload?.unique ?? true;
  }
}

export type TimeField = "user_time_of_week";

export class TimeTerm {
  user_time_of_week: TermValue | null;

  constructor(payload?: Partial<TimeTerm>) {
    this.user_time_of_week = payload?.user_time_of_week ?? null;
  }

  hasField(field: TimeField): boolean {
    return this[field] !== null;
  }

  getField(field: TimeField): TermValue | null {
    return this[field];
  }

  setField(field: TimeField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value) : null;
  }
}

export class TimeResource {
  user_time_of_week: TermValue[];

  constructor(payload?: Partial<TimeResource>) {
    this.user_time_of_week = payload?.user_time_of_week ?? [];
  }

  getResource(field: TimeField): TermValue[] {
    return this[field];
  }

  setResource(field: TimeField, values: TermValue[]): void {
    this[field] = values.map(item => new TermValue(item));
  }
}
