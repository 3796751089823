
import Vue from "vue";
import { debounce } from "lodash";
import { GeoField, GeoResource, GeoTerm } from "@/models/Modifiers/Terms/geo";
import ModifierTermMixin from "@/mixins/modifiers/TermModule";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import InitModifierOption from "@/models/InitModifierOption";
import { TermValue } from "@/models/ItemEntity";
import { DEBOUNCE_DELAY, MIN_CONTENT_SEARCH } from "@/services/Modifiers/const";
import { mapActions } from "vuex";
import { resolveListParams } from "@/utils/resolveObjectArray";
import { sleep } from "@/utils/convert";
import { DELAY_RESET_TERM } from "@/models/Modifiers/Terms";

export default Vue.extend({
  name: "GeoModule",
  mixins: [ModifierTermMixin],
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardAutocomplete,
    ButtonTooltip,
  },
  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    resources: new GeoResource(),
    field: new GeoTerm(),
    countryTerm: null,
    cityTerm: null,
    regionTerm: null,
    fieldMap: {
      city: { key: "prefix", value: "name" },
      country: { key: "prefix_3", value: "name" },
      region: { key: "key", value: "name" },
      lat_long_list: { key: "external_id", value: "name" },
    },
  }),
  created() {},
  async mounted() {},
  computed: {},
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("modifier_term", ["searchDataByTerm"]),

    /**
     * Se activa cuando se ejecuta el evento: focus en el campo `key`
     * @param key
     */
    async handleFocus(key: GeoField) {
      try {
        const resource: TermValue[] = await this.fetchTargetingByKey(key);
        (this.resources as GeoResource).setResource(key, resource);
      } catch (error) {
        console.error(`[ExchangeModule::handleFocus] key: ${key}`, { error });
        (this.resources as GeoResource).setResource(key, []);
      } finally {
      }
    },

    /**
     * Handler
     * Al detectar cambios en los campos agrega un nuevo 'term'
     */
    async handleChange(key: GeoField, item: TermValue | string) {
      const extractedTerm: TermValue = this.normalizeTerm(item);
      if (!extractedTerm?.value || extractedTerm?.value?.length < MIN_CONTENT_SEARCH) return;

      const modifiedTerm = await this.getTermModifier(key, extractedTerm);
      this.$emit("add-term", modifiedTerm);

      await sleep(DELAY_RESET_TERM);
      this.field[key] = null;
      this.syncSearcherData(key);
    },

    /**
     * Obtener la configuracion del tooltip
     * @param text
     */
    getConfigTooltip(text: string) {
      return this.tooltipData(text);
    },

    /**
     * Esta funcion es para sincronizar la busqueda dentro del componente
     * v-autocomplete con busqueda en api
     * @param key
     * @param term
     */
    syncSearcherData(key: GeoField, term: string | null = null): void {
      // Agregar mapeo de datos para los campos que sean de tipo search
      const fieldMap: Record<GeoField, any> = {
        region: "regionTerm",
        country: "countryTerm",
        city: "cityTerm",
        lat_long_list: "",
      };

      if (key in fieldMap) {
        this[fieldMap[key]] = term;
      }
    },

    async searcherByTerm(key: any, term: any, config: any) {
      const payload = { key, term };
      const result: any[] = await this.searchDataByTerm(payload);
      const newItems: TermValue[] = resolveListParams(result, config?.key, config?.value) as any as TermValue[];
      (this.resources as GeoResource).setResource(key, newItems);
    },

    async handleSearch(attribute: string, term: string) {
      if (!this.isValidSearchTerm(term)) return;
      const field = await this.getFieldByKey(attribute);
      await this.searcherByTerm(attribute, term, field);
    },

    isValidSearchTerm(value: string) {
      return !!value && value.length >= MIN_CONTENT_SEARCH;
    },
  },
  watch: {
    countryTerm: debounce(async function (val: string) {
      await this.handleSearch("country", val);
    }, DEBOUNCE_DELAY),

    regionTerm: debounce(async function (val: string) {
      await this.handleSearch("region", val);
    }, DEBOUNCE_DELAY),

    cityTerm: debounce(async function (val: string) {
      await this.handleSearch("city", val);
    }, DEBOUNCE_DELAY),
  },
});
