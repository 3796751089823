
import Vue from "vue";
import { ExchangeField, ExchangeResource, ExchangeTerm } from "@/models/Modifiers/Terms/exchange";
import { MIN_CONTENT_SEARCH } from "@/services/Modifiers/const";
import { TermValue } from "@/models/ItemEntity";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import InitModifierOption from "@/models/InitModifierOption";
import ModifierTermMixin from "@/mixins/modifiers/TermModule";
import { DELAY_RESET_TERM } from "@/models/Modifiers/Terms";
import { sleep } from "@/utils/convert";

export default Vue.extend({
  name: "ExchangeModule",
  mixins: [ModifierTermMixin],
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CardAutocomplete,
    CardTextField,
    ButtonTooltip,
  },

  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    resources: new ExchangeResource(),
    field: new ExchangeTerm(),
    fieldMap: {
      auction_type: { key: "extra", value: "description" },
      inventory_source: { key: "key", value: "name" },
    },
  }),
  computed: {},

  methods: {
    /**
     * Se activa cuando se ejecuta el evento: focus en el campo `key`
     * @param key
     */
    async handleFocus(key: ExchangeField) {
      try {
        const resource: TermValue[] = await this.fetchTargetingByKey(key);
        (this.resources as ExchangeResource).setResource(key, resource);
      } catch (error) {
        console.error(`[ExchangeModule::handleFocus] key: ${key}`, { error });
        (this.resources as ExchangeResource).setResource(key, []);
      } finally {
      }
    },

    /**
     * Handler
     * Al detectar cambios en los campos agrega un nuevo 'term'
     */
    async handleChange(key: ExchangeField, item: TermValue | string) {
      const extractedTerm: TermValue = this.normalizeTerm(item);
      if (!extractedTerm?.value || extractedTerm?.value?.length < MIN_CONTENT_SEARCH) return;

      const modifiedTerm = await this.getTermModifier(key, extractedTerm);
      this.$emit("add-term", modifiedTerm);

      await sleep(DELAY_RESET_TERM);
      this.field[key] = null;
      this.syncSearcherData(key);
    },

    /**
     * Obtener la configuracion del tooltip
     * @param text
     */
    getConfigTooltip(text: string) {
      return this.tooltipData(text);
    },

    /**
     * Esta funcion es para sincronizar la busqueda dentro del componente
     * v-autocomplete con busqueda en api
     * @param key
     * @param term
     */
    syncSearcherData(key: string, term: string | null = null): void {
      // Agregar mapeo de datos para los campos que sean de tipo search
    },
  },
});
