import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";

import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { ConfigModifierTerm, DeliverModifierField } from "@/models/Modifiers/Delivery/term";
import { ModifierOptionKey } from "@/models/Modifiers/Terms";
import { TermValue } from "@/models/ItemEntity";
import { FieldMapItem } from "@/mixins/modifiers/TermModule";

@Component({
  components: { CardAutocomplete },
  methods: {
    ...mapActions("targeting", {
      fetchTargetingKey: "getTargetingKey",
    }),
  },
})
export default class SelectorAutocomplete extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) moduleKey!: ModifierOptionKey;
  @Prop({ required: true }) termKey!: DeliverModifierField;
  @Prop({ required: true }) config!: ConfigModifierTerm;
  @Prop({ default: false }) disabled!: boolean;

  items: any[] = [];
  isLoading = false;

  created() {
    this.$nextTick(() => {
      //console.debug(`[SelectorAutocomplete::nextTick] moduleKey: ${this.moduleKey} termKey: ${this.termKey}`, { value: this.value });
    });
  }

  /**
   * Se activa cuando se ejecuta el evento: focus en el campo `key`
   * @param key
   */
  async handleFocus(key: DeliverModifierField) {
    try {
      const resource: TermValue[] = await this.fetchTargetingByKey(key);

      const items = resource.map(({ id, value }) => ({
        key: this.termKey,
        module_name: this.moduleKey,
        value: id,
        value_name: value,
      }));
      this.items = this.mergeArrays(items);
    } catch (error) {
      console.error(`[Autocomplete::handleFocus] key: ${key}`, { error });
      this.items = [];
    } finally {
    }
  }

  async fetchTargetingByKey(key: string): Promise<TermValue[]> {
    try {
      return await this.fetchTargetingKey({ key, object: await this.getFieldByKey() });
    } catch (error) {
      console.error(`[Autocomplete] fetchTargetingByKey - key: ${key}`, error);
      return [];
    }
  }

  /**
   * Fetch de datos [mapActions]
   * @param key
   * @returns
   */
  public fetchTargetingKey!: (payload: { key: string; object?: FieldMapItem }) => Promise<TermValue[]>;

  async getFieldByKey(): Promise<FieldMapItem> {
    return { key: this.config.value, value: this.config.text };
  }

  /**
   * Handler
   * Al detectar cambios en los campos agrega un nuevo 'term'
   */
  async handleChange(key: DeliverModifierField, item: TermValue | string) {
    //console.debug(`[Autocomplete::handleChange] key: ${key}`, item);
  }

  mergeArrays<T>(...arrays: T[][]): T[] {
    return [...new Set(arrays.flat())];
  }

  @Watch("value", { immediate: true, deep: true })
  private onValueChanged(newValue: any, oldValue: any): void {
    if(!newValue || (newValue && !Object.keys(newValue).length) || !newValue.value) return;
    const newItems = [...this.items, newValue];
    this.items = this.mergeArrays(newItems);
  }
}
