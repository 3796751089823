import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

interface Item {
  id: number;
  name: string;
  active: number;
  logo: string;
  segment_key: string;
  campaign_type: number[];
}

@Component({
  components: {},
})
export default class SelectorRadioRetailer extends Vue {
  @Prop({ type: Array, required: true }) items!: Item[];
  @Prop({ type: Object, default: null }) value!: Item;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Array, default: () => [] }) rules!: ((value: Item | null) => string | true)[];

  get getItems() {
    //return generateElements(this.items, 20); // debugs
    return this.items;
  }

  internalValue: Item | null = null;
  internalError: string | null = null;

  handleSelectItem(item: Item | null) {
    this.internalValue = item;
  }

  private validate() {
    this.internalError = null;
    for (const rule of this.rules) {
      const result = rule(this.internalValue);
      if (typeof result === "string") {
        this.internalError = result;
        break;
      }
    }
  }

  @Emit("input")
  public onModelValue(val: Item | null) {
    return val;
  }

  @Watch("value", { deep: true, immediate: true })
  private onChangeValue(val: Item | null): void {
    this.internalValue = val;
    this.validate();
  }

  @Watch("internalValue", { deep: true, immediate: true })
  private onChangeInternalValue(val: Item | null): void {
    this.onModelValue(val);
  }

  @Watch("rules", { deep: true, immediate: true })
  private onChangeRulesValue(val: Item | null): void {
    this.validate();
  }
}

/**
 * Genera `count` items para debugs
 * @param baseData
 * @param count
 * @returns
 */
function generateElements(baseData: Item[], count: number = 50): Item[] {
  const generated: Item[] = [];
  let currentId = Math.max(...baseData.map(item => item.id)) + 1;

  while (generated.length < count) {
    for (const item of baseData) {
      if (generated.length >= count) break;
      generated.push({
        ...item,
        id: currentId++,
      });
    }
  }

  return generated;
}
