import { TermValue } from "@/models/ItemEntity";

export type PlatformField =
  | "browser"
  | "browser_version"
  | "device_make"
  | "device_model"
  | "device_screen_size"
  | "device_type"
  | "operating_system"
  | "operating_system_version"
  | "bandwidth"
  | "carrier";

export class PlatformTerm {
  browser: TermValue | null;
  browser_version: TermValue | null;
  device_make: TermValue | null;
  device_model: TermValue | null;
  device_screen_size: TermValue | null;
  device_type: TermValue | null;
  operating_system: TermValue | null;
  operating_system_version: TermValue | null;
  bandwidth: TermValue | null;
  carrier: TermValue | null;

  constructor(payload?: Partial<PlatformTerm>) {
    this.browser = payload?.browser ?? null;
    this.browser_version = payload?.browser_version ?? null;
    this.device_make = payload?.device_make ?? null;
    this.device_model = payload?.device_model ?? null;
    this.device_screen_size = payload?.device_screen_size ?? null;
    this.device_type = payload?.device_type ?? null;
    this.operating_system = payload?.operating_system ?? null;
    this.operating_system_version = payload?.operating_system_version ?? null;
    this.bandwidth = payload?.bandwidth ?? null;
    this.carrier = payload?.carrier ?? null;
  }

  hasField(field: PlatformField): boolean {
    return this[field] !== null;
  }

  getField(field: PlatformField): TermValue | null {
    return this[field];
  }

  setField(field: PlatformField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class PlatformResource {
  browser: TermValue[];
  browser_version: TermValue[];
  device_make: TermValue[];
  device_model: TermValue[];
  device_screen_size: TermValue[];
  device_type: TermValue[];
  operating_system: TermValue[];
  operating_system_version: TermValue[];
  bandwidth: TermValue[];
  carrier: TermValue[];

  constructor(payload?: Partial<PlatformResource>) {
    this.browser = payload?.browser ?? [];
    this.browser_version = payload?.browser_version ?? [];
    this.device_make = payload?.device_make ?? [];
    this.device_model = payload?.device_model ?? [];
    this.device_screen_size = payload?.device_screen_size ?? [];
    this.device_type = payload?.device_type ?? [];
    this.operating_system = payload?.operating_system ?? [];
    this.operating_system_version = payload?.operating_system_version ?? [];
    this.bandwidth = payload?.bandwidth ?? [];
    this.carrier = payload?.carrier ?? [];
  }

  getResource(field: PlatformField): TermValue[] {
    return this[field];
  }

  setResource(field: PlatformField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  { id: "1", value: "Item Debug 1" },
  { id: "2", value: "Item Debug 2" },
];
