import { TermValue } from "@/models/ItemEntity";

export type ExchangeField = "auction_type" | "inventory_source";

export class ExchangeTerm {
  auction_type: TermValue | null;
  inventory_source: TermValue | null;

  constructor(payload?: Partial<ExchangeTerm>) {
    this.auction_type = payload?.auction_type ?? null;
    this.inventory_source = payload?.inventory_source ?? null;
  }

  hasField(field: ExchangeField): boolean {
    return this[field] !== null;
  }

  getField(field: ExchangeField): TermValue | null {
    return this[field];
  }

  setField(field: ExchangeField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class ExchangeResource {
  auction_type: TermValue[];
  inventory_source: TermValue[];

  constructor(payload?: Partial<ExchangeResource>) {
    this.auction_type = payload?.auction_type ?? [];
    this.inventory_source = payload?.inventory_source ?? [];
  }

  getResource(field: ExchangeField): TermValue[] {
    return this[field];
  }

  setResource(field: ExchangeField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  { id: "1", value: "Item Debug 1" },
  { id: "2", value: "Item Debug 2" },
];
