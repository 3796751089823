
import Vue from "vue";
import {
  isRequired,
  isNumber,
  isMax,
  isMin,
  // @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
// @ts-ignore
import { ModifiersOptions } from ".@/interfaces/modifier";
import { mapGetters } from "vuex";
import { ModifierOptionKey } from "@/models/Modifiers/Terms";

export default Vue.extend({
  name: "TableTerms",
  props: {
    headers: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default() {
        return [] as Array<ModifiersOptions>;
      },
    },
  },
  components: {
    CardTextField,
    IconBase,
    CardSwitch,
  },
  data: () => ({}),
  created() {},
  mounted() {},
  computed: {
    ...mapGetters("modifier_term", ["isOptionOverride"]),
    getRules() {
      return {
        isRequired,
        isNumber,
        isMax,
        isMin,
      };
    },
  },
  methods: {
    getModifierOption(key: ModifierOptionKey, isDinamic: boolean) {
      return this.getModifierOptionsWith(key, isDinamic);
    },
    hasOverrideMultiplier(item: any): boolean | undefined {
      if (!item) return false;
      return this.isOptionOverride(item.module_name, item.key);
    },
    async handleDelete(entity: any) {
      this.$emit("delete-item", entity);
    },
    handleChangeOverride(item: any, override_multiplier: number) {
      const { key, value } = item;
      this.$emit("change:override", { value, key, override_multiplier });
    },
  },
});
