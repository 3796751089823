
import Vue from "vue";
import { PlatformField, PlatformResource, PlatformTerm } from "@/models/Modifiers/Terms/platform";
import { TermValue } from "@/models/ItemEntity";
import { MIN_CONTENT_SEARCH } from "@/services/Modifiers/const";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import ModifierTermMixin from "@/mixins/modifiers/TermModule";
import InitModifierOption from "@/models/InitModifierOption";
import { sleep } from "@/utils/convert";
import { DELAY_RESET_TERM } from "@/models/Modifiers/Terms";

export default Vue.extend({
  name: "PlatformModule",
  mixins: [ModifierTermMixin],
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardTextField,
    CardAutocomplete,
    ButtonTooltip,
  },
  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    resources: new PlatformResource(),
    field: new PlatformTerm(),
    fieldMap: {
      browser: { key: "extra", value: "description" },
      browser_version: { key: "key", value: "description" },
      device_make: { key: "extra", value: "description" },
      device_model: { key: "key", value: "description" },
      device_screen_size: { key: "key", value: "description" },
      device_type: { key: "extra", value: "description" },
      operating_system: { key: "extra", value: "description" },
      operating_system_version: { key: "key", value: "description" },
      bandwidth: { key: "extra", value: "description" },
      carrier: { key: "extra", value: "description" },
    },
  }),
  computed: {},
  methods: {
    /**
     * Se activa cuando se ejecuta el evento: focus en el campo `key`
     * @param key
     */
    async handleFocus(key: PlatformField) {
      try {
        const resource: TermValue[] = await this.fetchTargetingByKey(key);
        (this.resources as PlatformResource).setResource(key, resource);
      } catch (error) {
        console.error(`[ExchangeModule::handleFocus] key: ${key}`, { error });
        (this.resources as PlatformResource).setResource(key, []);
      } finally {
      }
    },

    /**
     * Handler
     * Al detectar cambios en los campos agrega un nuevo 'term'
     */
    async handleChange(key: PlatformField, item: TermValue | string) {
      const extractedTerm: TermValue = this.normalizeTerm(item);
      if (!extractedTerm?.value || extractedTerm?.value?.length < MIN_CONTENT_SEARCH) return;

      const modifiedTerm = await this.getTermModifier(key, extractedTerm);
      this.$emit("add-term", modifiedTerm);

      await sleep(DELAY_RESET_TERM);
      this.field[key] = null;
      this.syncSearcherData(key);
    },

    /**
     * Obtener la configuracion del tooltip
     * @param text
     */
    getConfigTooltip(text: string) {
      return this.tooltipData(text);
    },
  },
  watch: {},
});
