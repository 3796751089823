import { TermValue } from "@/models/ItemEntity";

export type RangeIPField = "start" | "end";

export type UserField = "segment" | "ip_address" | "user_id";

export class RangeIP {
  start: string | null;
  end: string | null;

  constructor(payload?: Partial<RangeIP>) {
    this.start = payload?.start ?? null;
    this.end = payload?.end ?? null;
  }

  setRange(payload?: Partial<RangeIP>) {
    this.start = payload?.start ?? null;
    this.end = payload?.end ?? null;
  }

  /**
   * Retorna el rango de IPs en formato "start, end".
   * Si una de las propiedades es `null`, se omite en la cadena resultante.
   */
  toString(): string {
    return [this.start, this.end].filter(Boolean).join(", ");
  }
}

export class UserTerm {
  segment: TermValue | null;
  ip_address: TermValue | null;
  user_id: TermValue | null;

  constructor(payload?: Partial<UserTerm>) {
    this.segment = payload?.segment ?? null;
    this.ip_address = payload?.ip_address ?? null;
    this.user_id = payload?.user_id ?? null;
  }

  hasField(field: UserField): boolean {
    return this[field] !== null;
  }

  getField(field: UserField): TermValue | null {
    return this[field];
  }

  setField(field: UserField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class UserResource {
  segment: TermValue[];
  ip_address: TermValue[];
  user_id: TermValue[];

  constructor(payload?: Partial<UserResource>) {
    this.segment = payload?.segment ?? [];
    this.ip_address = payload?.ip_address ?? [];
    this.user_id = payload?.user_id ?? [];
  }

  getResource(field: UserField): TermValue[] {
    return this[field];
  }

  setResource(field: UserField, values: TermValue[]): void {
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

export class UserLoading {
  segment: boolean;
  ip_address: boolean;
  user_id: boolean;

  constructor(payload?: Partial<UserLoading>) {
    this.segment = payload?.segment ?? false;
    this.ip_address = payload?.ip_address ?? false;
    this.user_id = payload?.user_id ?? false;
  }

  isLoading(field: UserField): boolean {
    return this[field];
  }

  setLoading(field: UserField, value: boolean): void {
    this[field] = value;
  }
}
