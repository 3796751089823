import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { mapActions } from "vuex";

import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import { ConfigModifierTerm, DeliverModifierField } from "@/models/Modifiers/Delivery/term";
import { ModifierOptionKey } from "@/models/Modifiers/Terms";
import { sleep } from "@/utils/convert";
import { SearchByTermParams } from "@/interfaces/targeting";

@Component({
  components: { CardAutocomplete },
  methods: {
    ...mapActions("targeting", {
      fetchSearchByTerm: "getSearchByTerm",
    }),
  },
})
export default class AutocompleteWithSearcher extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) moduleKey!: ModifierOptionKey;
  @Prop({ required: true }) termKey!: DeliverModifierField;
  @Prop({ required: true }) config!: ConfigModifierTerm;
  @Prop({ default: false }) disabled!: boolean;

  items: any[] = [];
  searchTerm: string | null = null;
  isLoading = false;
  isFetchEnabled = true;

  private readonly configPayloads: Partial<Record<DeliverModifierField, SearchByTermParams>> = {
    app_name: {
      key: "app_name",
      by_attribute: "app_name",
      term: null,
      object: { key: "app_name", value: "app_name" },
    },
    site: {
      key: "site",
      term: null,
      object: { key: "site_id", value: "site_id" },
    },
    city: {
      key: "city",
      term: null,
      object: { key: "prefix", value: "name" },
    },
    country: {
      key: "country",
      term: null,
      object: { key: "prefix_3", value: "name" },
    },
    region: {
      key: "region",
      term: null,
      object: { key: "key", value: "name" },
    },
  };

  created() {
    this.$nextTick(() => {
      //console.debug(`[AutocompleteWithSearcher::nextTick] moduleKey: ${this.moduleKey} termKey: ${this.termKey}`, { value: this.value });
    });
  }

  private fetchSearchByTerm!: (payload: SearchByTermParams) => Promise<any[]>;

  /**
   * Obtiene la configuración de búsqueda con el término actualizado.
   */
  private getSearchConfig(newTerm: string | null): SearchByTermParams | undefined {
    const config = this.configPayloads[this.termKey];
    return config ? { ...config, term: newTerm } : undefined;
  }

  /**
   * Sincroniza el término de búsqueda con el input del usuario.
   */
  public updateSearchTerm(newTerm: string | null): void {
    this.searchTerm = newTerm;
  }

  /**
   * Deshabilita temporalmente la búsqueda para evitar múltiples llamadas seguidas.
   */
  public async disableFetchTemporarily(event?: any): Promise<void> {
    try {
      this.isFetchEnabled = false;
      await sleep(1000);
    } finally {
      this.isFetchEnabled = true;
    }
  }

  /**
   * Busca elementos en función del término ingresado.
   */
  private async fetchSearchResults(newTerm: string | null): Promise<void> {
    if (!newTerm) return;

    try {
      this.isLoading = true;
      const payload = this.getSearchConfig(newTerm);
      if (!payload) return;

      const results = await this.fetchSearchByTerm(payload);
      console.debug(`[fetchSearchResults]`, { payload, results, newTerm });

      const items = results.map(({ id, value }) => ({
        key: this.termKey,
        module_name: this.moduleKey,
        value: id,
        value_name: value,
      }));
      this.items = this.mergeArrays(items);
    } catch (error) {
      console.error("Error en fetchSearchResults:", error);
    } finally {
      this.isLoading = false;
    }
  }

  mergeArrays<T>(...arrays: T[][]): T[] {
    return [...new Set(arrays.flat())];
  }

  /**
   * Ejecuta la búsqueda con debounce para evitar llamadas innecesarias.
   */
  private debounceSearch = debounce(this.fetchSearchResults, 450);

  @Watch("searchTerm", { immediate: false, deep: true })
  private onSearchTermChanged(newValue: string | null, oldValue: string | null): void {
    if (oldValue === null || !newValue || !this.isFetchEnabled) return;
    this.debounceSearch(newValue);
  }

  @Watch("value", { immediate: true, deep: true })
  private onValueChanged(newValue: any, oldValue: any): void {
    if (!newValue || (newValue && !Object.keys(newValue).length) || !newValue.value) return;
    const newItems = [...this.items, newValue];
    this.items = this.mergeArrays(newItems);
  }
}
