import { TermValue } from "@/models/ItemEntity";

export type ContentField = "content_category" | "language" | "content_rating";

export class ContentTerm {
  content_category: TermValue | null;
  language: TermValue | null;
  content_rating: TermValue | null;

  constructor(payload?: Partial<ContentTerm>) {
    this.content_category = payload?.content_category ?? null;
    this.language = payload?.language ?? null;
    this.content_rating = payload?.content_rating ?? null;
  }

  hasField(field: ContentField): boolean {
    return this[field] !== null;
  }

  getField(field: ContentField): TermValue | null {
    return this[field];
  }

  setField(field: ContentField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class ContentResource {
  content_category: TermValue[];
  language: TermValue[];
  content_rating: TermValue[];

  constructor(payload?: Partial<ContentResource>) {
    this.content_category = payload?.content_category ?? [];
    this.language = payload?.language ?? [];
    this.content_rating = payload?.content_rating ?? [];
  }

  getResource(field: ContentField): TermValue[] {
    return this[field];
  }

  setResource(field: ContentField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  { id: "1", value: "Item Debug 1" },
  { id: "2", value: "Item Debug 2" },
];
