import { Vue, Component } from "vue-property-decorator";

@Component
export default class RouteValidationMixin extends Vue {
  get getRouteId(): number {
    if (!this.isRouteEdit) return NaN;
    return Number(this.$route.params.id);
  }

  get isRouteEdit(): boolean {
    return this.validateRoute(/\/edit\/\d+$/);
  }

  get isRouteCreate(): boolean {
    return this.validateRoute(/\/create$/);
  }

  get isModifierEditRoute(): boolean {
    return this.validateRoute(/\/modifiers\/edit\/\d+$/);
  }

  private validateRoute(pattern: RegExp): boolean {
    return pattern.test(this.$route.path);
  }
}
