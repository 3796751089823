
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { isNull, isUndefined, some, sortBy, isEmpty } from "lodash";
import { TypeLoading } from "@/interfaces/loading";
import { getPermisionModifiers } from "@/utils/permissionResolve";
import { ModifierOption, ModifierOptionKey } from "@/models/Modifiers/Terms";
import { AlertMessage, AlertMessageType } from "@/models/Modifiers/alerts";
import {
  ModifierForm,
  ModifierFormField,
  ModifierFormResource,
  ModifierFormRule,
  ModifierLoading,
} from "@/models/Modifiers/form";
import { isMax } from "@/services/rule-services";
import {
  getDeliveryModifierOptionItem,
  getEndpointKeyByType,
  ModifierModule,
  ModifierType,
} from "@/services/Modifiers/types";
import {
  getConfigAlerts,
  MAX_MODIFIER_VALUE,
  MODIFIER_STATIC_ITEMS,
  MODULES_BY_MODIFIER_TYPE,
} from "@/services/Modifiers/const";
import {
  prepareDataDelivery,
  prepareTableHeaders,
  prepareTableHeadersDelivery,
} from "@/views/Admin/Modifiers/Logic/utils";
import ModifierAlertMessage from "@/views/Admin/Modifiers/Alert.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import TableTermsBid from "@/views/Admin/Modifiers/Terms/List.vue";
import TableTermsDelivery from "@/views/Admin/Modifiers/List/TableDelivery.vue";
import PanelOptions from "@/views/Admin/Modifiers/Terms/PanelOptions.vue";
import CardAction from "@/components/Content/CardAction.vue";
import { PayloadModifierBuilder } from "@/models/Modifiers/Terms/payload";
import RouteValidationMixin from "@/mixins/RouteValidation";
import { CardActionType } from "@/interfaces/action";
import { sortByCustomOrder } from "@/services/Modifiers/functions";
// import { scrollToTop } from "@/utils/services-global";

export default Vue.extend({
  name: "OverviewModifier",
  mixins: [RouteValidationMixin],
  props: {
    entity: {
      type: Object,
      default: function () {
        return new ModifierForm();
      },
    },
    loadings: {
      type: ModifierLoading,
      default: function () {
        return new ModifierLoading();
      },
    },
    advertisers: {
      type: Array,
      required: true,
    },
    types: {
      type: Array,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    associationMode: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    deliveryOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    termDelivery: {
      type: Array,
      default: function () {
        return [] as any[];
      },
    },
    disabledModifier: { type: Boolean, default: false },
  },
  components: {
    CardTextField,
    CardAutocomplete,
    CardSwitch,
    TableTermsBid,
    TableTermsDelivery,
    PanelOptions,
    ButtonTooltip,
    ModifierAlertMessage,
    CardAction,
  },
  data: () => ({
    valid: false,
    showDefaultWeight: false,
    fallback_weight: "1",
    formRules: new ModifierFormRule(),
    formResources: new ModifierFormResource(),
    alertMessages: [] as AlertMessage[],
  }),
  created() {},
  async mounted() {
    this.setConfigAlerts();
    await this.loadInitialData();
  },
  beforeDestroy() {
    this.resetData();
  },
  computed: {
    ...mapGetters("profile", ["getAbility"]),
    ...mapGetters("modifier_term", ["getterModifierOptions", "getModifierOptionsWith"]),

    getModifierOptions(): ModifierOption {
      return this.getterModifierOptions;
    },

    getModules(): any[] {
      const modules: any[] = this.modules;
      const modifierType = this.entity.modifier_type;
      const modulesByModifierType: string[] = MODULES_BY_MODIFIER_TYPE[modifierType?.value ?? "Bid Modifier"];
      const filtered = modules.filter(m => modulesByModifierType.some(module => m.extra == module));
      const sorted = sortByCustomOrder(filtered, modulesByModifierType, "extra");
      return sorted;
    },

    getTypes(): any[] {
      return this.types;
    },

    getEntity: {
      get(): ModifierForm {
        return this.entity;
      },
      set(val: ModifierForm) {
        this.$emit("input", val);
      },
    },

    // Terms
    prepareTableHeaders() {
      return [
        {
          text: "Module",
          align: "start",
          sortable: false,
          filterable: false,
          value: "module_name",
        },
        {
          text: "Key",
          align: "start",
          sortable: false,
          filterable: false,
          value: "key",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          filterable: false,
          value: "matching_type_name",
        },
        {
          text: "Value",
          align: "center",
          sortable: false,
          filterable: false,
          value: "value_name",
        },
        {
          text: "Multiplier",
          align: "center",
          sortable: false,
          filterable: false,
          value: "modifier",
          width: "125px",
        },
        {
          text: "Dinamic",
          align: "center",
          sortable: false,
          filterable: false,
          value: "override_multiplier",
          width: "125px",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          filterable: false,
          value: "actions",
        },
      ];
    },

    getTerms(): any[] {
      const matchings = this.getMatchingTypes;

      // Ocultar el mensaje de "required"
      this.setVisibleMessage("required", false);

      const terms: any[] = this.entity?.terms ?? [];

      return terms.map(e => ({
        ...e,
        matching_type_name: e.matching_type_name ?? matchings.find(mat => mat.id === e.matching_type_id)?.value,
        module_name: e.module_name ?? this.modules.find(mod => mod.id === e.module_id)?.extra,
      }));
    },

    getTermsDelivery(): any[] {
      this.setVisibleMessage("required", false);
      return this.termDelivery ?? [];
    },

    getSelectedModule() {
      return String(this.entity.module?.value).toLowerCase();
    },

    getDefaultPanelSize() {
      return this.getDefaultSize({ sm: "12", md: "4", lg: "4" });
    },

    getDefaultTableSize() {
      return this.getDefaultSize({ sm: "12", md: "8", lg: "8" });
    },

    isEdit(): boolean {
      return this.isModifierEditRoute;
    },

    isEditCurrentModel(): boolean {
      return this.isRouteEdit;
    },

    getMatchingTypes(): any[] {
      return this.$store.state.modifier_options.matching_types;
    },

    getTableHeaders() {
      if (this.isModifierType("Delivery Modifier" as ModifierType)) {
        // Si no hay términos de entrega, prepara los encabezados con una lista vacía
        const termsDelivery: any[] = isEmpty(this.getTermsDelivery)
          ? []
          : this.getTermsDelivery.flatMap(t => t.targeting);
        return prepareTableHeadersDelivery(termsDelivery);
      } else {
        // Para otros casos, prepara los encabezados estándar
        return prepareTableHeaders();
      }
    },

    canCreate() {
      if (this.isEdit) {
        return this.canEdit;
      }

      // Verifica los permisos basados en el tipo de modificador
      const modifierPermissions = {
        "Delivery Modifier": this.getPermission.subComponent.delivery_modifier.create_delivery,
        "Bid Modifier": this.getPermission.subComponent.bid_modifier.create_bid,
      };

      // Si el tipo de modificador es conocido, se verifica el permiso correspondiente
      const modifierType = this.getEntity.modifier_type?.value;

      if (modifierType) {
        return this.getAbility.can(modifierPermissions[modifierType], this.getPermission.subject);
      }

      // Si no se cumple ninguna de las condiciones, retornar false
      return false;
    },

    canEdit() {
      // Mapeo de tipos de modificadores a sus permisos correspondientes
      const modifierPermissions = {
        "Delivery Modifier": this.getPermission.subComponent.delivery_modifier.update_delivery,
        "Bid Modifier": this.getPermission.subComponent.bid_modifier.update_bid,
      };

      // Verificar si el tipo de modificador es válido y devolver el permiso correspondiente
      const modifierType = this.getEntity.modifier_type?.value;

      if (modifierType) {
        return this.getAbility.can(modifierPermissions[modifierType], this.getPermission.subject);
      }

      // Si no coincide con ninguno de los tipos, retornar false
      return false;
    },

    getPermission() {
      return getPermisionModifiers();
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("models", ["bidModelsList"]),
    ...mapActions("modifier_term", ["fetchDataGet", "resetModifierOptions"]),

    getItemsByKey(key: ModifierFormField) {
      const itemsMap = {
        advertiser_id: this.advertisers,
        modifier_type_id: this.types,
        delivery_modifiers_option: MODIFIER_STATIC_ITEMS["delivery_modifiers_type"],
        module_id: this.getModules,
        bid_model_id: this.bidModels,
      };

      return itemsMap[key] ?? [];
    },

    getModifierOption(key: ModifierOptionKey, isDinamic: boolean) {
      return this.getModifierOptionsWith(key, isDinamic);
    },

    /**
     * Carga de datos iniciales
     */
    async loadInitialData() {
      try {
        await this.setLoadingData(TypeLoading.loading);

        this.setModifierType();
        this.setFallbackWeight();

        await Promise.allSettled([this.dispatchMatchingTypes(), this.fetchBidModels()]);
      } catch (error) {
        console.error("Error loading initial data:", error);
      } finally {
        await this.setLoadingData();
      }
    },

    /**
     * Configura el peso de respaldo con un valor predeterminado si no está definido.
     */
    setFallbackWeight() {
      this.fallback_weight = this.entity.fallback_weight ?? "1";
    },

    setModifierType() {
      if (this.disabledModifier) {
        this.getEntity.modifier_type_id = 110;
      }
    },

    getDefaultSize(defaultSize: { sm: string; md: string; lg: string }): { sm: string; md: string; lg: string } {
      // Verifica si el tipo de módulo es "time", si es así retorna tamaño completo
      if (this.isModuleType("time" as ModifierModule)) {
        return { sm: "12", md: "12", lg: "12" };
      }
      // Si no es "time", retorna el tamaño por defecto proporcionado
      return defaultSize;
    },

    /**
     * Función que devuelve los modulos para cada tipo de modifier
     *
     * @param items Lista de "extras" disponibles
     * @param modules Lista de los modules
     * @return
     */

    isModifierType(type: ModifierType) {
      return this.getEntity.modifier_type?.value === type;
    },

    isModuleType(type: ModifierModule) {
      return this.getSelectedModule === type.toLowerCase();
    },

    tooltipData(params: any) {
      return {
        icon: "mdi-help-circle-outline",
        text: params,
        to: "",
        right: params?.right ?? true,
      };
    },

    handlerClearTermDelivery() {
      this.termDelivery = [];
    },

    handleChangeFallback(value: any) {
      this.fallback_weight = value;
    },

    handleChangeModifierType() {
      this.getEntity.module = null;
    },

    async validate(): Promise<boolean> {
      let form = this.$refs.form;
      return await form.validate();
    },

    async validateTerms(): Promise<boolean> {
      const entities = this.getTerms;

      // Si entities es nulo o indefinido, retornar true
      if (isNull(entities) || isUndefined(entities)) {
        return true;
      }

      // Si no hay términos, mostrar mensaje y retornar false
      if (!entities.length) {
        this.setVisibleMessage("required", true);
        return false;
      }

      // Ocultar mensaje si hay términos
      this.setVisibleMessage("required", false);

      // Validar si todos los términos cumplen la condición
      return entities.every(entity => isMax(entity?.modifier, MAX_MODIFIER_VALUE));
    },

    async validateTermsDelivery(): Promise<boolean> {
      const entities: any[] = this.getTermsDelivery;

      // Si entities es nulo o indefinido, retornar true
      if (isNull(entities) || isUndefined(entities)) {
        return true;
      }

      // Si no hay términos, mostrar mensaje y retornar false
      if (!entities.length) {
        this.setVisibleMessage("required", true);
        return false;
      }

      // Ocultar mensaje si hay términos
      this.setVisibleMessage("required", false);

      // Verificar si alguna entidad no cumple con las reglas
      const isValid = !entities.some(
        entity =>
          entity.targeting.length > 3 || entity.targeting.length < 1 || entity.weight < 0 || entity.weight > 100,
      );

      return isValid;
    },

    async fetchBidModels() {
      await this.setLoadingField(TypeLoading.loading);
      await this.bidModelsList()
        .then(async res => {
          this.bidModels =
            res.map(r => {
              return {
                id: Number(r.id),
                value: r.value,
              };
            }) || [];
          await this.setLoadingField();
        })
        .catch(async err => {
          await this.setLoadingField();
        });
    },

    // Método para manejar acciones
    async handleAction(action: { type: string }): Promise<void> {
      try {
        switch (action.type) {
          case CardActionType.CANCEL:
            this.handleCancel();
            break;
          case CardActionType.SAVE:
            await this.handleSubmit();
            break;
        }
      } catch (error) {
        console.error(`[handleAction] type: ${action.type}`, error);
      }
    },

    handleCancel() {
      this.$router.push({ name: "ModifiersIndex" });
    },

    async handleSubmit() {
      const handler = this.isModifierType("Delivery Modifier" as ModifierType)
        ? this.handleSubmitDelivery
        : this.handleSubmitBidModifier;

      await handler();
    },

    async handleSubmitDelivery() {
      this.setVisibleMessage("max", false);
      await this.formRules.addRules();

      // Validaciones
      const isValid = (await this.validate()) && (await this.validateTermsDelivery());

      console.debug(
        `[handleSubmitDelivery] isValid: ${isValid} - validate: ${await this.validate()} - validateTermsDelivery: ${await this.validateTermsDelivery()}`,
      );

      //!isValid && scrollToTop();

      if (!isValid) return;

      const entity = {
        ...this.getEntity,
        fallback_weight: parseFloat(this.fallback_weight),
        terms: this.getTermsDelivery,
      };

      const isDeliveryModifier = this.isModifierType("Delivery Modifier" as ModifierType);
      const preparedEntity = prepareDataDelivery(entity);

      // Emitir evento según el modo de asociación
      const eventPayload = {
        entity: preparedEntity,
        isDeliveryModifier,
      };

      if (this.associationMode) {
        this.$emit("create-modifier", {
          ...eventPayload,
          executeClearTermFunction: this.handlerClearTermDelivery,
        });
      } else {
        this.$emit(this.isEdit ? "update-entity" : "create-entity", eventPayload);
      }

      // Resetear reglas del formulario
      this.formRules.resetRules();
    },

    async handleSubmitBidModifier() {
      await this.formRules.addRules();

      // Validaciones
      const isValid = (await this.validate()) && (await this.validateTerms());

      console.debug(
        `[handleSubmitBidModifier] isValid: ${isValid} - validate: ${await this.validate()} - validateTerms: ${await this.validateTerms()}`,
      );

      //!isValid && scrollToTop();

      if (!isValid) return;

      const newEntity = {
        ...this.getEntity,
        terms: this.getTerms,
      };

      const isBidModifier = this.isModifierType("Bid Modifier" as ModifierType);

      const payload = new PayloadModifierBuilder(newEntity).build();

      const eventPayload = {
        entity: payload,
        isBidModifier,
      };

      // Emitir evento según el modo de asociación
      const event = this.associationMode ? "create-modifier" : this.isEdit ? "update-entity" : "create-entity";

      console.debug(
        `[handleSubmitBidModifier] event: ${event} - isBidModifier: ${isBidModifier}`, { eventPayload },
      );

      this.$emit(event, eventPayload);

      // Resetear reglas del formulario
      this.formRules.resetRules();
    },

    handleChangeModule(event: any) {
      this.setVisibleMessage("required", false);
    },

    getSelectedValueById(data: any, id: Number) {
      if (data) {
        let result = data.find(e => e.id == id);
        return result ? result.value.toUpperCase() : undefined;
      }
      return undefined;
    },

    async handleAddTerm(item: any) {
      try {
        if (this.isModifierType("Delivery Modifier" as ModifierType)) {
          this.setVisibleMessage("max", false);
          await this.handleAddTermDelivery(item);
          return;
        }

        // Manejo específico para "interstitial"
        if (item.key === "interstitial") {
          await this.handleInterstitialTerm(item);
        } else {
          await this.handleStandardTerm(item);
        }
      } catch (error) {
        console.error("Error en handleAddTerm:", error);
      }
    },

    // Maneja la lógica para agregar un término "interstitial"
    handleInterstitialTerm(item: any) {
      const existingTerm = this.getTerms.find(i => i.module_name === item.module_name && i.key === item.key);

      // Si ya existe, se elimina el término
      if (existingTerm) {
        this.handleRemoveTermIntersticial(existingTerm);
      }

      // Se agrega el nuevo término
      this.addTermToEntity(item);
    },

    // Maneja la lógica para términos estándar
    async handleStandardTerm(item: any) {
      const termExists = await this.verifyExistingTerm(item);
      this.setVisibleMessage("exist", termExists);

      if (!termExists) {
        this.addTermToEntity(item);
      }
    },

    // Función para agregar un término a la entidad
    addTermToEntity(item: any) {
      const elements = [...this.getTerms, item];
      this.entity.terms = elements;
    },

    setVisibleMessage(key: AlertMessageType, show: boolean) {
      (this.alertMessages as AlertMessage[])?.find(a => a.key === key)?.setVisible(show);
    },

    async handleAddTermDelivery(item: any) {
      this.setVisibleMessage("max", false);

      if (isEmpty(this.getTermsDelivery)) {
        // Si no existen términos de entrega, crea un nuevo término
        this.addNewTermDelivery(item);
      } else {
        // Si ya existen términos de entrega, verifica si el término existe
        const termExist = this.isTargetExist(item.key);
        if (termExist) {
          // Si el término existe, se agrega uno nuevo
          this.addNewTermDelivery(item);
        } else {
          // Si no existe y hay espacio, se agrega el nuevo término
          if (this.getTermsDelivery[0].targeting.length > 2) {
            this.setVisibleMessage("max", true);
            return;
          }

          this.addTargetToFirstTerm(item);
        }
      }
    },

    // Función para agregar un nuevo término de entrega
    addNewTermDelivery(item: any) {
      const newTerm = {
        targeting: [
          {
            key: item.key,
            module_name: item.module_name,
            value: item.value,
            value_name: item.value_name,
          },
        ],
        rank: this.getTermsDelivery.length + 1,
        weight: 1,
      };
      this.getTermsDelivery.push(newTerm);
    },

    // Función para verificar si el término ya existe en los términos de entrega
    isTargetExist(key: string) {
      return this.getTermsDelivery[0].targeting.some(target => target.key === key);
    },

    // Función para agregar un nuevo objetivo al primer término de entrega
    addTargetToFirstTerm(item: any) {
      this.getTermsDelivery[0].targeting.push(item);
      this.updateTermDelivery(this.getTermsDelivery);
    },

    handleRemoveTermTime(item: any) {
      this.entity.terms = this.getTerms.filter(m => {
        return m.value != item;
      });
    },

    async handleDeleteDeliveryColumn(key: string) {
      this.getTermsDelivery.forEach(term => {
        const newTargetting = term.targeting.filter(target => target.key != key);
        term.targeting = newTargetting;
      });

      if (this.getTermsDelivery.length > 0) {
        if (this.getTermsDelivery[0].targeting.length == 0) {
          this.$emit("delete-terms");
        }
      }
    },

    async handleDeleteDeliveryItem(item: any) {
      const index = this.getTermsDelivery.findIndex(term => term.rank === item.rank);

      if (index === -1) return; // Si no se encuentra el índice, termina la función

      // Eliminar el término de entrega
      this.getTermsDelivery.splice(index, 1);

      // Actualizar el rank de los términos restantes
      this.updateRanks();
    },

    // Función para actualizar el rank de los términos después de eliminar uno
    updateRanks() {
      this.getTermsDelivery = this.getTermsDelivery.map((term, index) => {
        term.rank = index + 1; // Ajusta el rank de forma secuencial
        return term;
      });
    },

    handleRemoveTermIntersticial(item: any) {
      this.entity.terms = this.getTerms.filter(m => {
        return m.value != item.value;
      });
    },

    async verifyExistingTerm(item: { module_name: any; key: any; value: any }) {
      const terms: Array<any> = this.getTerms;
      return some(terms, { module_name: item.module_name, key: item.key, value: item.value });
    },

    handleDeleteTerm(item: any) {
      if (this.isModuleType("time" as ModifierModule)) {
        this.$refs.pnlOption.removeTermTime(item);
        this.handleRemoveTermTime(item.value);
      } else {
        this.entity.terms = this.getTerms.filter(e => {
          return e != item;
        });
      }
    },

    handleChangeOverride(event: { key: string; value: string; override_multiplier: 0 | 1 }) {
      const { key, value, override_multiplier } = event;

      const term = this.entity.terms.find(t => t.key === key && t.value === value);
      if (term) term.override_multiplier = override_multiplier;

      // La instancia de `getTerms o getTermsDelivery` es para actualizar el listado de terms con los datos actualizados de `override_multiplier` de entity
      if (this.isModifierType("Delivery Modifier" as ModifierType)) this.getTerms;
      if (this.isModifierType("Bid Modifier" as ModifierType)) this.getTermsDelivery;
    },

    handleGetTerm(term_name: any) {
      let filterEntitys = this.entity.terms.filter(t => t.key === term_name);
      this.$refs.pnlOption.setTerm(filterEntitys, term_name);
    },

    async dispatchMatchingTypes() {
      return this.$store.dispatch("modifier_options/getMatchingTypes", {
        root: true,
      });
    },

    updateTermDelivery(value: any[]) {
      // Aplanamos los targeting de todos los términos
      const flattened = value.flatMap(term => term.targeting);

      // Obtener las claves únicas de las columnas
      const columns = [...new Set(flattened.map(target => target.key))];

      // Añadir términos faltantes a cada columna
      value.forEach(term => {
        columns.forEach(column => {
          if (!term.targeting.some(target => target.key === column)) {
            term.targeting.push({
              key: column,
              value: null,
            });
          }
        });
      });
    },

    async checkTerms() {
      if (isEmpty(this.termDelivery)) {
        this.fallback_weight = "1";
      }
    },

    verifyModifierOptionType() {
      const isDeliveryModifier: boolean = this.isModifierType("Delivery Modifier" as ModifierType);
      if (!isDeliveryModifier) {
        this.getEntity.default_weight = undefined;
      }
      this.entity.delivery_modifiers_option = isDeliveryModifier ? getDeliveryModifierOptionItem() : undefined;
    },

    setConfigAlerts() {
      this.alertMessages = getConfigAlerts(); // DP-788
    },

    resetData() {
      this.resetModifierOptions();
      this.showDefaultWeight = false;
      this.fallback_weight = "1";
      this.formRules = new ModifierFormRule();
      this.formResources = new ModifierFormResource();
      this.setConfigAlerts();
    },
  },
  watch: {
    termDelivery: function (value) {
      this.updateTermDelivery(value);
      this.checkTerms();
    },
    "entity.fallback_weight"(val) {
      this.fallback_weight = val ?? "1";
    },
    "entity.modifier_type": {
      async handler(val, oldVal) {
        this.entity.modifier_type_id = val?.id;
        this.verifyModifierOptionType();
        const modifierTypeValue: ModifierType = val?.value ?? "Bid Modifier";
        const endpoint = getEndpointKeyByType(modifierTypeValue);
        this.fetchDataGet(endpoint);
      },
      deep: true,
      immediate: true,
    },
    "entity.module"(val) {
      this.entity.module_id = val?.id;
      this.setVisibleMessage("required", false);
    },
  },
});
