import Vue from "vue";
import { mapActions } from "vuex";
import { Component, Prop } from "vue-property-decorator";
import InitModifierOption from "@/models/InitModifierOption";
import { MATCHING_TYPE_DEFAULT } from "@/services/Modifiers/const";
import { TermValue } from "@/models/ItemEntity";
import { isObject } from "lodash";
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";
import { ModifierMatchingType } from "@/services/Modifiers/types";

export interface FieldMapItem {
  key: string;
  value: string;
}

export interface ModuleProp {
  id: number;
  value?: string;
  extra?: string;
}

@Component({
  methods: {
    ...mapActions("targeting", { fetchTargetingKey: "getTargetingKey" }),
  },
})
export default class ModifierTermMixin extends Vue {
  valid = false;
  entity = new InitModifierOption();
  fieldMap: Record<string, FieldMapItem> = {};
  resources: TermValue[] = [];

  @Prop({ type: Object, default: () => ({ id: NaN }) }) module!: ModuleProp;
  @Prop({ type: Array, default: () => [] }) matching_types!: ModuleProp[];
  @Prop({ type: Object, default: () => ({}) }) modifiers_options!: Record<string, any>;

  /**
   * Fetch de datos [mapActions]
   * @param key
   * @returns
   */
  public fetchTargetingKey!: (payload: { key: string; object?: FieldMapItem }) => Promise<TermValue[]>;

  created() {
    this.$nextTick(() => {
      this.entity = new InitModifierOption({ module_id: this.module.id, module_nme: this.module.value });
    });
  }

  async getFieldByKey(key: string): Promise<FieldMapItem> {
    return this.fieldMap[key] || { key: "external_id", value: "name" };
  }

  getMatchingType(type: ModifierMatchingType = MATCHING_TYPE_DEFAULT) {
    return this.matching_types.find(m => m.value?.toLowerCase() === type.toLowerCase()) ?? null;
  }

  normalizeTerm(item: TermValue | string): TermValue {
    return isObject(item) ? { ...item } : { id: item, value: item };
  }

  async fetchTargetingByKey(key: string): Promise<TermValue[]> {
    try {
      const field = await this.getFieldByKey(key);
      return await this.fetchTargetingKey({ key, object: field });
    } catch (error) {
      console.error(`[ModifierTermMixin] fetchTargetingByKey - key: ${key}`, error);
      return [];
    }
  }

  async getTermModifier(key: string, term: TermValue, type?: ModifierMatchingType) {
    const matchingType = this.getMatchingType(type);
    const modifierOption = this.modifiers_options[key] ?? {};
    const hasOverrideMultiplier = modifierOption.override_multiplier !== undefined;

    this.entity = new InitModifierOption(
      key,
      String(term.id),
      String(term.value),
      this.module.id,
      this.module.extra,
      matchingType?.id,
      matchingType?.value,
      hasOverrideMultiplier ? (modifierOption.override_multiplier == "true" ? 1 : 0) : undefined,
    );

    return this.entity;
  }

  /**
   * Tootip data
   * @param params
   * @returns
   */
  tooltipData(text: string) {
    return prepareTooltipData(this.$t(text));
  }

  /**
   * Esta funcion es para sincronizar la busqueda dentro del componente
   * v-autocomplete con busqueda en api
   * @param key
   * @param term
   */
  syncSearcherData(key: string, term: string | null = null): void {}
}
