import { TermValue } from "@/models/ItemEntity";

export type AppSiteField =
  | "app_bundle_list"
  | "app_name"
  | "app_id_list"
  | "deal_id_list"
  | "placement_id"
  | "placement_list"
  | "publisher_id"
  | "publisher_id_list"
  | "site"
  | "site_list"
  | "deal_id"
  | "domain_list";

export class AppSiteTerm {
  app_bundle_list: TermValue | null;
  app_name: TermValue | null;
  app_id_list: TermValue | null;
  deal_id_list: TermValue | null;
  placement_id: TermValue | null;
  placement_list: TermValue | null;
  publisher_id: TermValue | null;
  publisher_id_list: TermValue | null;
  site: TermValue | null;
  site_list: TermValue | null;
  deal_id: TermValue | null;
  domain_list: TermValue | null;

  constructor(payload?: Partial<AppSiteTerm>) {
    this.app_bundle_list = payload?.app_bundle_list ?? null;
    this.app_name = payload?.app_name ?? null;
    this.app_id_list = payload?.app_id_list ?? null;
    this.deal_id_list = payload?.deal_id_list ?? null;
    this.placement_id = payload?.placement_id ?? null;
    this.placement_list = payload?.placement_list ?? null;
    this.publisher_id = payload?.publisher_id ?? null;
    this.publisher_id_list = payload?.publisher_id_list ?? null;
    this.site = payload?.site ?? null;
    this.site_list = payload?.site_list ?? null;
    this.deal_id = payload?.deal_id ?? null;
    this.domain_list = payload?.domain_list ?? null;
  }

  hasField(field: AppSiteField): boolean {
    return this[field] !== null;
  }

  getField(field: AppSiteField): TermValue | null {
    return this[field];
  }

  setField(field: AppSiteField, value: TermValue | null): void {
    this[field] = value ? new TermValue(value.id, value.value) : null;
  }
}

export class AppSiteResource {
  app_bundle_list: TermValue[];
  app_name: TermValue[];
  app_id_list: TermValue[];
  deal_id_list: TermValue[];
  placement_id: TermValue[];
  placement_list: TermValue[];
  publisher_id: TermValue[];
  publisher_id_list: TermValue[];
  site: TermValue[];
  site_list: TermValue[];
  deal_id: TermValue[];
  domain_list: TermValue[];

  constructor(payload?: Partial<AppSiteResource>) {
    this.app_bundle_list = payload?.app_bundle_list ?? [];
    this.app_name = payload?.app_name ?? [];
    this.app_id_list = payload?.app_id_list ?? [];
    this.deal_id_list = payload?.deal_id_list ?? [];
    this.placement_id = payload?.placement_id ?? [];
    this.placement_list = payload?.placement_list ?? [];
    this.publisher_id = payload?.publisher_id ?? [];
    this.publisher_id_list = payload?.publisher_id_list ?? [];
    this.site = payload?.site ?? [];
    this.site_list = payload?.site_list ?? [];
    this.deal_id = payload?.deal_id ?? [];
    this.domain_list = payload?.domain_list ?? [];
  }

  getResource(field: AppSiteField): TermValue[] {
    return this[field];
  }

  setResource(field: AppSiteField, values: TermValue[]): void {
    //this[field] = debugItems;
    this[field] = values.map(item => new TermValue(item.id, item.value));
  }
}

const debugItems: TermValue[] = [
  {id: "1", value: "Item Debug 1"},
  {id: "2", value: "Item Debug 2"}
];