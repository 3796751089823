
import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import { isUndefined, isNull } from "lodash";
import {
	initModifier,
	initModifierCampaignAssociation,
	// @ts-ignore
} from "@/utils/initData";
import Overview from "./../../../Modifiers/overview.vue";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { getDefaultSortingOption } from "@/utils/filter-global";
// @ts-ignore
import { Paginated } from "@/interfaces/paginated";
import { isProd } from "@/services/process-service";

// Constants
const ASSOCIATION_NULLABLE = -1;
const MODIFIER_TYPE_ID = 111;

export default Vue.extend({
	name: "DeliveryContent",
	props: {
		campaign: {
			type: Object,
			default: function () {
				return undefined;
			},
		},
		defaultIndexRoute: {
			type: String,
			default: function() {
				return "ModifiersIndex"
			}
		},
		line: {
			type: Object,
			default: function () {
				return undefined;
			},
		},
		disabledModifier: {
			type: Boolean,
			default: false,
		}

	},
	components: {
		CardTextField,
		CardAutocomplete,
		Overview,
		Alertize
	},
	data: () => ({
		valid: false,
		currentModelSelected: 0,
		modifier: initModifier(),
		association: initModifierCampaignAssociation(),
		options: getDefaultSortingOption("id"),
		paginated: { page: 1, limit: 25 } as Paginated,
		filters:{},
		modifiersOptions: {},
		deliveryList: [],
		term:{
			delivery: []
		}
	}),
	async created() {},
	async mounted() {
		this.$nextTick(async () => {
			await this.onLoadResources();
		});
	},
	computed: {
		...mapGetters("profile", ["isRolReport"]),

		isReadOnly(){
			return this.isRolReport;
		},

		isCampaign() {
			return this.campaign !== undefined;
		},

		isLine() {
			return this.line !== undefined;
		},

		getAdvertisers(): any[] {return this.$store.state.advertiser.advertisers_list;},

		isCampaignByLine(){
			return this.campaign.optimization_strategy_id == 7;
		},

		getModifierTypes(): any[] {return this.$store.state.modifier.modifier_types_list;},
		getAssociation() {
			if ( !isUndefined(this.getRelationshipEntity.delivery_modifier_id) && !isNull(this.getRelationshipEntity.delivery_modifier_id) ) {
				this.association.delivery_modifier_option = this.getRelationshipEntity.delivery_modifier_option;
				this.association.modifier_id = this.getRelationshipEntity.delivery_modifier_id;
			}
			return this.association;
		},
		getRelationshipEntity() {
			if(this.isCampaign) return this.campaign;
			if(this.isLine) return this.line;
			return  undefined;
		},
		getCampaignsPacing() {
			return this.$store.state.custom_list.isCampaignPacingById;
		},
		getCampaignsByLine() {
			return this.$store.state.custom_list.optimization_strategy_id;
		},
		getByCampaigns() {
			return this.$store.state.custom_list.isOptimizationStrategyById;
		},
		isDisabled(){
			if (!isUndefined(this.campaign)) {
				return this.isCampaignByLine ? true : this.isDisableCampaing;
			} 
			if (!isUndefined(this.line)) {
				return this.isDisableLine;
			}
		},
		isDisableCampaing(){
			if(isUndefined(this.campaign)) return false;
			if( (this.campaign.optimization_strategy_id === 7) || (this.campaign.optimization_strategy_id === 8)){
				return false;
			}
		},
		isDisableLine(){
			if(!isUndefined(this.line) && this.line?.line_pacing !== null){
				if(this.line.strategy_id === 9 &&  this.line.line_pacing_id  !== 12 ){
					return false;
				}
				if(this.line.strategy_id === 10 &&  this.line.line_pacing_id  !== 12){
					return false;
				}
				if(this.line.strategy_id === 11 &&  this.line.line_pacing_id !== 12){
					return false;
				}
			}
			return true;
		},
		disableDelivery(){
			if ( this.campaign ) {
				return this.isDisableCampaing;
			} else if ( this.line ) {
				return this.isDisableLine;
			}
		},
		getModifiers(){
			return this.deliveryList;
		},
		getModifier() {
			this.setModifierType();
			this.modifier.advertiser_id = this.getRelationshipEntity?.advertiser_id;
			return this.modifier;
		},
		getModules(): any[] {return this.$store.state.modifier.modifier_modules_list;},
		initialiceModifier() {
			this.modifier = initModifier();
			this.setModifierType();
			this.modifier.advertiser_id = this.getRelationshipEntity.advertiser_id;
			return this.modifier;
		},
		getTermDelivery(){
			return this.term.delivery;
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("modifier", ["getModifiersOptions", "listDelivery"]),

		setModifierType() {
			const modifierType = this.getModifierTypes.find(t => t.id === MODIFIER_TYPE_ID);
			this.modifier.modifier_type = modifierType;
			this.modifier.modifier_type_id = modifierType?.id;
		},

		async initialiceDelivery(){
			this.modifier.modifier_id = this.getRelationshipEntity?.delivery_modifier_id;
			this.setModifierType();
			this.association.modifier_id = this.getRelationshipEntity?.delivery_modifier_id;
		},
		updateSelectedTabIndex(index: number) {
			this.currentModelSelected = index;
		},
		async onLoadResources(){
			await this.setLoadingData(TypeLoading.loading);
			await this.dispatchModules();
			await this.dispatchModifierTypes();
			await this.dispatchDeliveryModifier();
			await this.dispatchAdvertisers();
			await this.initialiceDelivery();
			this.modifiersOptions = await this.getModifiersOptions();
			await this.setLoadingData();
		},
		async handleAssociation(canContinue: boolean = false) {

			const campaignId = this.isCampaign ? this.campaign.id : this.line.campaign_id;
			const lineId = this.isLine ? this.line.id : undefined;

			this.association.campaign_id = campaignId;
			this.association.line_item_id = lineId;

			if(!isProd()) {
				console.debug(`[DEBUG] deliveryModifier isCampaign: ${this.isCampaign} campaignId: ${campaignId} ->> isLine: ${this.isLine}  lineId: ${lineId}`, {
					campaign: this.campaign,
					line: this.line,
					association: this.association,
				});
			}
			
			if ( this.association.modifier_id !== ASSOCIATION_NULLABLE ) {
				if ( !(await this.validate()) ) {return;}
				this.association.modifier_type = "delivery_modifier"
				const payload = {
					entity: this.association,
					module: "DELIVERY_MODIFIERS",
					continue: canContinue
				};
				this.$emit("handle-create-association", payload);
			}else{
				let entity = {};
				if ( !isUndefined(this.campaign) ) {
					entity = { campaign_id: campaignId }
				} else {
					entity = { line_item_id: lineId }
				}
				this.$emit("handle-clear-association", {
					entity: {
						...entity,
						modifier_id: null,
					},
					module: "delivery_modifier",
					continue: canContinue
				});
			}
		},
		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},
		handlerClearTerm(){
			this.term.delivery = [];
			this.modifier = initModifier();
			this.setModifierType();
			this.modifier.advertiser_id = this.getRelationshipEntity.advertiser_id;
		},
		handleSubmitModifier(params: any) {
			this.$emit("handle-submit-modifier", {
				...params,
				isDeliveryModifier: true,
				executeDispatchFunction: () => {this.dispatchDeliveryModifier();},
				executeClearFunction: () => {
					this.initialiceModifier;
					this.handlerClearTerm();
				;}
			});
		},
		handleCancel() {
			this.$emit("handle-cancel-modifiers");
		},
		async dispatchModifierTypes() {
			return this.$store.dispatch("modifier/getModifierTypes", {
				root: true,
			});
		},
		async dispatchModifier() {
			return this.$store.dispatch("modifier/list", {
				filters: { modifier_type_extra: "delivery_modifier" },
				options: { sort: "name", order: "asc" },
				paginated: this.paginated
			});
		},
		async dispatchDeliveryModifier() {
			this.filters = {
				modifier_type_extra: "delivery_modifier",
				advertiser_id: this.getRelationshipEntity.advertiser_id
			};
			const params = {
				options: this.options,
				filters: this.filters,
				paginated: this.paginated
			}
			await this.listDelivery(params)
			.then((response) => {
				this.deliveryList = response;
				this.setModifierType();
			})
			.catch((err) => {

			});
		},
		async dispatchModules() {
			return this.$store.dispatch("modifier/getModulesList", {
				root: true,
			});
		},
		async dispatchAdvertisers() {
			return this.$store.dispatch("advertiser/list", {
				filters: { active: true },
				options: { sort: "name", order: "asc" }
			});
		},

		async handleDeleteTerms(){
			this.term.delivery = [];
		}
	},
	watch: {
	}
});
