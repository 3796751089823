import { DeliverModifierField } from "@/models/Modifiers/Delivery/term";
import i18n from "@/plugins/i18n";
import { isEmpty } from "lodash";

const CATEGORY = {
  APP_SITE: "appSite",
  CONTENT: "content",
  ENVIRONMENT: "environment",
  EXCHANGE: "exchange",
  GEO: "geo",
  PLATFORM: "platform",
  VIDEO: "video",
  TIME: "time",
  AUDIO: "audio",
} as const;

export const dictionary = Object.freeze({
  // app_site
  app_bundle_list: CATEGORY.APP_SITE,
  app_name: CATEGORY.APP_SITE,
  app_id_list: CATEGORY.APP_SITE,
  deal_id_list: CATEGORY.APP_SITE,
  placement_id: CATEGORY.APP_SITE,
  placement_list: CATEGORY.APP_SITE,
  publisher_id: CATEGORY.APP_SITE,
  publisher_id_list: CATEGORY.APP_SITE,
  site: CATEGORY.APP_SITE,
  site_list: CATEGORY.APP_SITE,
  deal_id: CATEGORY.APP_SITE,
  domain_list: CATEGORY.APP_SITE,

  // content
  content_rating: CATEGORY.CONTENT,
  language: CATEGORY.CONTENT,

  // environment
  environment_type: CATEGORY.ENVIRONMENT,
  interstitial: CATEGORY.ENVIRONMENT,
  interstitial_type: CATEGORY.ENVIRONMENT,
  rewarded: CATEGORY.ENVIRONMENT,
  native_layout: CATEGORY.ENVIRONMENT,

  // exchange
  auction_type: CATEGORY.EXCHANGE,
  inventory_source: CATEGORY.EXCHANGE,

  // geo
  country: CATEGORY.GEO,
  region: CATEGORY.GEO,
  city: CATEGORY.GEO,
  lat_long_list: CATEGORY.GEO,

  // platform
  browser: CATEGORY.PLATFORM,
  browser_version: CATEGORY.PLATFORM,
  device_make: CATEGORY.PLATFORM,
  device_model: CATEGORY.PLATFORM,
  device_screen_size: CATEGORY.PLATFORM,
  device_type: CATEGORY.PLATFORM,
  operating_system: CATEGORY.PLATFORM,
  operating_system_version: CATEGORY.PLATFORM,
  bandwidth: CATEGORY.PLATFORM,
  carrier: CATEGORY.PLATFORM,

  // video
  companion_required: CATEGORY.VIDEO,
  playback_method: CATEGORY.VIDEO,
  player_size: CATEGORY.VIDEO,
  start_delay: CATEGORY.VIDEO,
  video_placement_type: CATEGORY.VIDEO,

  // time
  user_time_of_week: CATEGORY.TIME,

  // audio
  audio_start_delay: CATEGORY.AUDIO,
});

export function prepareTableHeaders() {
  return [
    {
      text: "Module",
      align: "start",
      sortable: false,
      filterable: false,
      value: "module_name",
    },
    {
      text: "Key",
      align: "start",
      sortable: false,
      filterable: false,
      value: "key",
    },
    {
      text: "Type",
      align: "start",
      sortable: false,
      filterable: false,
      value: "matching_type_name",
    },
    {
      text: "Value",
      align: "center",
      sortable: false,
      filterable: false,
      value: "value_name",
    },
    {
      text: "Multiplier",
      align: "center",
      sortable: false,
      filterable: false,
      value: "modifier",
      width: "125px",
    },
    {
      text: "Dinamic",
      align: "center",
      sortable: false,
      filterable: false,
      value: "override_multiplier",
      width: "125px",
    },
    {
      text: "",
      align: "center",
      sortable: false,
      filterable: false,
      value: "actions",
    },
  ];
}

export function prepareTableHeadersDelivery(term: any[]) {
  let arr = new Array();
  const firtObj = {
    text: i18n.t(`modifier.fields.priority`),
    align: "center",
    sortable: false,
    filterable: false,
    value: "rank",
    width: "50px",
  };
  arr.push(firtObj);
  if (!isEmpty(term)) {
    term.forEach(element => {
      if (arr.findIndex(e => e.value == element.key) != -1) return;
      arr.push(prepareItemHeader(element.key));
    });
  }
  const WeightCol = {
    text: "Weight",
    align: "center",
    sortable: false,
    filterable: false,
    width: "125px",
    value: "weight",
  };
  const porCol = {
    text: "%",
    align: "center",
    sortable: false,
    filterable: false,
    width: "125px",
    value: "porcentaje",
  };
  const deleteCol = {
    text: "",
    align: "center",
    sortable: false,
    filterable: false,
    width: "20px",
    value: "delete",
  };
  arr.push(WeightCol);
  arr.push(porCol);
  arr.push(deleteCol);
  return arr;
}

export function deleteTerm(term: any[]) {
  let arr = new Array();
  term.forEach(element => {
    if (arr.findIndex(e => e.text == element.module_name) != -1) return;
    arr.splice(element.key, element.module_name);
  });
  return arr;
}

export function prepareItemHeader(key: any) {
  let prefix = dictionary[key];
  return {
    align: "start",
    filterable: false,
    sortable: false,
    text: i18n.t(`modifier.labels.${prefix}.${key}.label`),
    value: key,
  };
}

export function prepareTooltipData(params: any) {
  return {
    icon: "mdi-information-outline",
    text: params,
    to: "",
    top: true,
    max_width: "500",
  };
}

export function prepareDataDelivery(data) {
  const { advertiser_id, name, fallback_weight, terms, alternative_id, id, modifier_type_id, active } = data;

  const newTerms: {}[] = [];
  terms.forEach(term => {
    const newTerm = {
      weight: term.weight,
      rank: term.rank,
      targeting: term.targeting.map(target => {
        const newTarget = {
          key: target.key,
          value: target.value,
        };

        return newTarget;
      }),
    };

    if (term.targeting.filter(target => target.value).length > 0) {
      newTerms.push(newTerm);
    }
  });

  return {
    advertiser_id,
    name,
    fallback_weight,
    terms: newTerms,
    alternative_id,
    id,
    modifier_type_id,
    active,
  };
}

export function getModifiersOptions() {
  return [
    // app_site
    "app_bundle_list",
    "app_name",
    "app_id_list",
    "deal_id_list",
    "placement_id",
    "placement_list",
    "publisher_id",
    "publisher_id_list",
    "site",
    "site_list",
    "deal_id",
    "domain_list",

    // content
    "content_rating",
    "language",

    // environment
    "environment_type",
    "interstitial",
    "interstitial_type",
    "rewarded",
    "native_layout",

    // exchange
    "auction_type",
    "inventory_source",

    //geo
    "country",
    "region",
    "city",
    "lat_long_list",

    // video
    "companion_required",
    "playback_method",
    "player_size",
    "start_delay",
    "video_placement_type",
  ] as DeliverModifierField[];
}
