
import Vue from "vue";
import { mapActions } from "vuex";
import { debounce } from "lodash";
import { getDataVariables } from "@/utils/initData";
import { TypeLoading } from "@/interfaces/loading";
import { AppSiteField, AppSiteResource, AppSiteTerm } from "@/models/Modifiers/Terms/app_site";
import { DEBOUNCE_DELAY, MIN_CONTENT_SEARCH } from "@/services/Modifiers/const";
import { TermValue } from "@/models/ItemEntity";
import ModifierTermMixin from "@/mixins/modifiers/TermModule";
import InitModifierOption from "@/models/InitModifierOption";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSearcherAttribute from "@/components/Content/CardSearcherAttribute.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import { sleep } from "@/utils/convert";
import { DELAY_RESET_TERM } from "@/models/Modifiers/Terms";

export default Vue.extend({
  name: "AppSiteModule",
  mixins: [ModifierTermMixin],
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CardAutocomplete,
    CardSearcherAttribute,
    CardTextField,
    ButtonTooltip,
  },

  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    resources: new AppSiteResource(),
    field: new AppSiteTerm(),
    by_attribute: {
      app_name: "app_name",
      site: "site_id",
    },
    appNameTerm: null,
    siteTerm: null,
    fieldMap: {
      app_name: { key: "app_name", value: "app_name" },
      site: { key: "site_id", value: "site_id" },
    },
  }),

  computed: {},

  methods: {
    ...mapActions("loading", ["setLoadingData"]),
    ...mapActions("targeting", ["getAppNameByAtribute"]),
    ...mapActions("targeting", ["getSitesByAtribute"]),

    /**
     * Se activa cuando se ejecuta el evento: focus en el campo `key`
     * @param key
     */
    async handleFocus(key: AppSiteField) {
      try {
        const resource: TermValue[] = await this.fetchTargetingByKey(key);
        (this.resources as AppSiteResource).setResource(key, resource);
      } catch (error) {
        console.error(`[ExchangeModule::handleFocus] key: ${key}`, { error });
        (this.resources as AppSiteResource).setResource(key, []);
      } finally {
      }
    },

    /**
     * Handler
     * Al detectar cambios en los campos agrega un nuevo 'term'
     */
    async handleChange(key: AppSiteField, item: TermValue | string) {
      const extractedTerm: TermValue = this.normalizeTerm(item);
      if (!extractedTerm?.value || extractedTerm?.value?.length < MIN_CONTENT_SEARCH) return;

      const modifiedTerm = await this.getTermModifier(key, extractedTerm);
      this.$emit("add-term", modifiedTerm);

      await sleep(DELAY_RESET_TERM);
      this.field[key] = null;
      this.syncSearcherData(key);
    },

    /**
     * Obtener la configuracion del tooltip
     * @param text
     */
    getConfigTooltip(text: string) {
      return this.tooltipData(text);
    },

    /**
     * Esta funcion es para sincronizar la busqueda dentro del componente
     * v-autocomplete con busqueda en api
     * @param key
     * @param term
     */
    syncSearcherData(key: string, term: string | null = null): void {
      // Agregar mapeo de datos para los campos que sean de tipo search
      const fieldMap: Record<"app_name" | "site", any> = {
        app_name: "appNameTerm",
        site: "siteTerm",
      };

      if (key in fieldMap) {
        this[fieldMap[key]] = term;
      }
    },

    getFilterOptions(term: string) {
      return getDataVariables()[term];
    },

    /**
     * Handler
     * Captura el evento del Enter
     * Concatena el valor con un (,) cada vez que presiona Enter
     */
    addCommaHandler(key: string) {
      this.field[key] = this.field[key]?.concat(",");
    },

    /**
     * Busquedas con parametro: 'by_attribute'
     */
    async fetchByAtribute(params: { key: string; term: string; by_attribute: string }) {
      switch (params.key) {
        case "app_name":
          return await this.getAppNameByAtribute(params);
        case "site":
          return await this.getSitesByAtribute(params);
      }
    },

    async fetchingByAttribute(key: any, value: any, object: { key: any; value: any }) {
      await this.setLoadingData(TypeLoading.loading);
      const result: Array<any> = await this.fetchByAtribute({
        key: key,
        term: value,
        by_attribute: this.by_attribute[key],
        object: object,
      });
      this.resources[key] = result;
      await this.setLoadingData();
    },

    async handleSearch(attribute: string, value: string) {
      if (!this.isValidSearchTerm(value)) return;
      const field = await this.getFieldByKey(attribute);
      await this.fetchingByAttribute(attribute, value, field);
    },

    isValidSearchTerm(value: string) {
      return !!value && value.length >= MIN_CONTENT_SEARCH;
    },
  },

  watch: {
    appNameTerm: debounce(async function (val: string) {
      await this.handleSearch("app_name", val);
    }, DEBOUNCE_DELAY),

    siteTerm: debounce(async function (val: string) {
      await this.handleSearch("site", val);
    }, DEBOUNCE_DELAY),
  },
});
