
import { find, first, isArray, isEmpty, isUndefined, values } from "lodash";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import Separator from "@/components/Content/Separator.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";

import Vue from "vue";
import { mapActions } from "vuex";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";

export default Vue.extend({
  name: "Time",
  props: {
    time: {
      type: Object,
      default: function () {},
    },
    timezones: {
      type: Object,
      default: function () {},
    },
    line_item: {
      type: Object,
      required: true,
    },
    data_variables: {
      type: Object,
      default: function () {
        return {};
      },
    },
    predicates: {
      type: Object,
      default: function () {
        return {};
      },
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    is_predicates: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: { CardSwitch, Separator, ButtonTooltip, CardAutocomplete },
  data: () => ({
    fab: false,
    active: false,
    selected: [] as any[],
    weekdays: [] as any[],
    timezonesList: [] as any[],
    hours: [] as any[],
    end_period: 11,
    divide_period: 12,
    headers: [
      {
        text: "Day",
        align: "center",
        sortable: false,
        value: "key",
      },
    ] as any[],
    items: [] as any[],
    targeting_predicate_id: 28,
    minutes: 0,
    start: 0,
    end: 0,
    timezonesSelectedOptions: [] as any[],
    maxDisplaysConfig: {
      xs: 1,
      sm: 2,
      md: 3,
      lg: 4,
    },
  }),
  async created() {
    this.targeting_predicate_id = await this.getTargetingPredicateID();
    try {
      await this.initialize();
    } catch (error) {
      //console.error("time::created", { message: error.message });
    }
  },
  async mounted() {
    this.timezonesList = await this.dispatchTimezonesKeys();
    this.timezonesSelectedOptions = this.filterSelectedTimezones();
    this.updateTargetingTimezones();
    this.weekdays = [
      {
        key: 0,
        value: "Persons10.charts.date_of_week.1",
      },
      {
        key: 1,
        value: "Persons10.charts.date_of_week.2",
      },
      {
        key: 2,
        value: "Persons10.charts.date_of_week.3",
      },
      {
        key: 3,
        value: "Persons10.charts.date_of_week.4",
      },
      {
        key: 4,
        value: "Persons10.charts.date_of_week.5",
      },
      {
        key: 5,
        value: "Persons10.charts.date_of_week.6",
      },
      {
        key: 6,
        value: "Persons10.charts.date_of_week.7",
      },
    ];

    const range_h = this.getRange(0, 23);

    range_h.forEach((r: number) => {
      this.hours.push({
        key: r,
        value: r % this.divide_period,
        display: r >= 12 ? r + " PM" : r + " AM",
      });
    });

    const h = await this.getHeaderItems();

    h.forEach((header: any) => {
      this.headers.push(header);
    });

    await this.matchingItems();
  },
  computed: {
    maxDisplays() {
      const breakpoint = this.$vuetify.breakpoint.name;
      return this.maxDisplaysConfig[breakpoint] || 3;
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),

    async getTargetingPredicateID() {
      const targeting_terms: Array<any> = this.time.user_time_of_week.targeting_terms;
      if (isEmpty(targeting_terms)) return this.predicates.INCLUDED;
      const targeting_term: any = first(targeting_terms);

      return targeting_term ? targeting_term.targeting_predicate_id : this.predicates.INCLUDED;
    },

    async getTargetingData() {
      const targeting_terms: Array<any> = this.time.user_time_of_week.targeting_terms;

      if (isEmpty(targeting_terms)) return undefined;

      const targeting_term: any = first(targeting_terms);

      if (isEmpty(targeting_term)) return undefined;

      return targeting_term;
    },

    async initialize() {
      this.time.user_time_of_week.targeting_predicate_id = this.targeting_predicate_id;

      if (!this.is_edit) return;

      const targeting_term: any = await this.getTargetingData();

      let init_targeting_terms: Array<any> = [];

      if (!isUndefined(targeting_term) && !isUndefined(targeting_term.value)) {
        targeting_term.value.forEach((v: any) => {
          init_targeting_terms.push({
            targeting_key_id: targeting_term.targeting_key_id,
            targeting_predicate_id: targeting_term.targeting_predicate_id,
            value: v,
          });
        });
      }

      this.$emit("init-targeting-time", {
        tab: "time",
        key: "user_time_of_week",
        targeting_terms: init_targeting_terms,
      });
    },

    async getHeaderItems() {
      const result: Array<any> = [];
      this.hours.forEach((h: any) => {
        result.push({
          value: `${h.value}`,
          display: `${h.value % this.divide_period} ${h.key > this.end_period ? "PM" : "AM"}`,
          key: `${h.key}`,
          align: "center",
        });
      });
      return result;
    },

    getItems() {
      return this.items;
    },
    tooltipData(params: any) {
      return {
        icon: "mdi-information-outline",
        text: params,
        to: "",
        right: params?.right ?? true,
      };
    },

    async matchingItems() {
      this.weekdays.forEach((w: any) => {
        var rows: Array<any> = [];

        this.hours.forEach((h: any) => {
          if (this.minutes === 0) {
            this.end += 59;
          } else {
            this.start += 60;
            this.end += 60;
          }

          this.minutes += 60;

          rows.push({
            value: `${h.value}`,
            key: `${h.key}`,
            text: `${this.start},${this.end}`,
            align: "center",
            display: h.display,
          });
        });

        this.items.push({
          key: `${w.key}`,
          value: this.$t(w.value),
          rows: rows,
        });
      });
    },

    getRange(min: number, max: number) {
      return [...Array(max - min + 1).keys()].map(i => i + min);
    },

    hasTargetingChecked(r: any) {
      if (this.readonly) return false;
      const targeting_term: Array<any> = this.time.user_time_of_week.targeting_terms;
      const finded = find(targeting_term, { value: `${r.text}` });
      return !isEmpty(finded);
    },

    updateTargetingChecked(r: any, week: any) {
      if (this.readonly) return false;
      this.$emit("update-selected-checked", {
        tab: "time",
        key: "user_time_of_week",
        value: r.text,
        timeSelected: r,
        weekDay: week,
        targeting_predicate_id: this.targeting_predicate_id,
      });
    },

    updateTargetingTimezones() {
      this.$emit("update-selected-timezones", {
        tab: "time",
        key: "timezones",
        value: this.timezonesSelectedOptions,
        targeting_predicate_id: this.targeting_predicate_id,
      });
    },

    getColorGrid() {
      if (this.is_predicates) {
        if (this.targeting_predicate_id === this.predicates.INCLUDED) {
          return "green--text green";
        } else {
          return "red--text red";
        }
      } else {
        return "green--text green";
      }
    },
    removeOption(option) {
      this.timezonesSelectedOptions = this.timezonesSelectedOptions.filter(selected => selected.id !== option.id);
      this.updateTargetingTimezones();
    },

    async dispatchTimezonesKeys() {
      return this.$store.dispatch("timezone/getTimezones");
    },

    filterSelectedTimezones() {
      const timezoneIds = this.timezones?.map(tz => tz.id);
      return this.timezonesList?.filter(timezone => timezoneIds?.includes(timezone.id));
    },
  },
  watch: {
    targeting_predicate_id(val) {
      this.time.user_time_of_week.targeting_predicate_id = val;
      this.$emit("update-selected-predicate", {
        tab: "time",
        key: "user_time_of_week",
        value: val,
      });
    },
    async "time.user_time_of_week.targeting_terms"(val) {
      if (val.some((v: any) => isArray(v.value))) {
        await this.initialize();
      }
    },
  },
});
