
import Vue from "vue";
import { RangeIP, UserField, UserLoading, UserResource, UserTerm } from "@/models/Modifiers/Terms/user";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import InitModifierOption from "@/models/InitModifierOption";
import ModifierTermMixin from "@/mixins/modifiers/TermModule";
import { TermValue } from "@/models/ItemEntity";
import { DEBOUNCE_DELAY, MIN_CONTENT_SEARCH } from "@/services/Modifiers/const";
import { FetchConfigParams, ModifierMatchingType } from "@/services/Modifiers/types";
import { isRequired } from "@/services/rule-services";
import { debounce } from "lodash";
import { mapActions } from "vuex";
import { resolveListParams } from "@/utils/resolveObjectArray";
import { ResultPaginate } from "@/interfaces/paginated";
import { sleep } from "@/utils/convert";
import { DELAY_RESET_TERM } from "@/models/Modifiers/Terms";

export default Vue.extend({
  name: "UserModule",
  mixins: [ModifierTermMixin],
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardTextField,
    CardAutocomplete,
    ButtonTooltip,
  },
  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    resources: new UserResource(),
    rangeIp: new RangeIP(),
    field: new UserTerm(),
    loading: new UserLoading(),
    rules: {
      start: [] as any[],
      end: [] as any[],
    },
    segmentTerm: null,
    fieldMap: {
      segment: { key: "key", value: "name" },
      user_id: { key: "extra", value: "description" },
    },
    payloadMap: {
      segment: {
        key: "segment",
        entity: "segment",
        mode: "paginated",
        filters: {},
        options: { sort: "name", order: "desc" },
        paginated: { limit: 50, page: 1 },
      } as FetchConfigParams,
    },
  }),
  created() {
    this.$nextTick(() => {
      this.rangeIp = new RangeIP();
    });
  },
  computed: {
    getRules() {
      return {
        isRequired,
      };
    },

    getIpRange(): string {
      return (this.rangeIp as RangeIP).toString();
    },
  },
  methods: {
    ...mapActions("modifier_term", ["fetchWithConfig"]),

    clearRules() {
      this.rules = { start: [], end: [] };
      this.resetValidation();
      (this.field as UserTerm).setField("ip_address", null);
      (this.rangeIp as RangeIP).setRange();
    },

    async addRules() {
      this.rules = {
        start: [this.getRules.isRequired, this.validateIp(this.rangeIp?.start)],
        end: [this.getRules.isRequired, this.validateIp(this.rangeIp?.end)],
      };
    },

    async resetValidation() {
      let form = this.$refs.form;
      form.resetValidation();
    },

    async validate() {
      let form = this.$refs.form;
      const valid = await form.validate();
      return await valid;
    },

    async getTermModifier(key: string, term: TermValue, type: ModifierMatchingType = "Equals") {
      const matchingType = this.getMatchingType(type);
      const modifierOption = this.modifiers_options[key] ?? {};
      const hasOverrideMultiplier = modifierOption.override_multiplier !== undefined;

      this.entity = new InitModifierOption(
        key,
        String(term.id),
        String(term.value),
        this.module.id,
        this.module.value,
        matchingType?.id,
        matchingType?.value,
        hasOverrideMultiplier ? (modifierOption.override_multiplier == "true" ? 1 : 0) : undefined,
      );

      return this.entity;
    },

    async handleSetRangeIpAsTerm(): Promise<void> {
      try {
        await this.addRules();

        if (!(await this.validate())) return;

        this.toggleLoading("ip_address", true);

        const extractedTerm = this.setIpField(this.getIpRange);
        const entity = await this.fetchTermModifier(extractedTerm);

        await sleep(100);
        this.$emit("add-term", entity);

        this.clearRules();
      } catch (error) {
        console.error("[handleSetRangeIpAsTerm]", error);
      } finally {
        this.toggleLoading("ip_address", false);
      }
    },

    validateIp(ip?: string): boolean {
      if (!ip) return false;

      const ipRegex = /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/;

      const match = ip.match(ipRegex);
      if (!match) return false;

      const octets = match.slice(1).map(Number);
      return octets[0] <= 245 && octets.every(octet => octet >= 0 && octet <= 255);
    },

    /**
     * Se activa cuando se ejecuta el evento: focus en el campo `key`
     * @param key
     */
    async handleFocus(key: UserField) {
      try {
        this.toggleLoading(key, true);
        const resource: TermValue[] = await this.fetchTargetingByKey(key);
        this.setResource(key, resource);
      } catch (error) {
        console.error(`[ExchangeModule::handleFocus] key: ${key}`, { error });
        this.setResource(key, []);
      } finally {
        this.toggleLoading(key, false);
      }
    },

    /**
     * Handler
     * Al detectar cambios en los campos agrega un nuevo 'term'
     */
    async handleChange(key: UserField, item: TermValue | string) {
      const extractedTerm: TermValue = this.normalizeTerm(item);
      if (!extractedTerm?.value || extractedTerm?.value?.length < MIN_CONTENT_SEARCH) return;

      const modifiedTerm = await this.getTermModifier(key, extractedTerm);
      this.$emit("add-term", modifiedTerm);

      await sleep(DELAY_RESET_TERM);
      this.field[key] = null;
      this.syncSearcherData(key);
    },

    /**
     * Obtener la configuracion del tooltip
     * @param text
     */
    getConfigTooltip(text: string) {
      return this.tooltipData(text);
    },

    /**
     * Esta funcion es para sincronizar la busqueda dentro del componente
     * v-autocomplete con busqueda en api
     * @param key
     * @param term
     */
    syncSearcherData(key: string, term: string | null = null): void {
      // Agregar mapeo de datos para los campos que sean de tipo search
      const fieldMap: Record<"segment", any> = {
        segment: "segmentTerm",
      };

      if (key in fieldMap) {
        this[fieldMap[key]] = term;
      }
    },

    async searcherByTerm(key: any, term: string, config: any) {
      try {
        this.toggleLoading(key, true);
        const payload = this.payloadMap[key] as FetchConfigParams;
        payload.filters = { name: term };

        const response: ResultPaginate = await this.fetchWithConfig(payload);
        const newItems: TermValue[] = resolveListParams(
          response.data,
          config?.key,
          config?.value,
        ) as any as TermValue[];

        this.setResource(key, newItems);
      } catch (error) {
        this.setResource(key, []);
      } finally {
        this.toggleLoading(key, false);
      }
    },

    async handleSearch(attribute: string, term: string) {
      if (!this.isValidSearchTerm(term)) return;
      const field = await this.getFieldByKey(attribute);
      await this.searcherByTerm(attribute, term, field);
    },

    isValidSearchTerm(value: string) {
      return !!value && value.length >= MIN_CONTENT_SEARCH;
    },

    toggleLoading(field: UserField, state: boolean): void {
      (this.loading as UserLoading).setLoading(field, state);
    },

    setResource(field: UserField, value: TermValue[]): void {
      (this.resources as UserResource).setResource(field, value);
    },

    setIpField(ipRange: string): TermValue {
      const termValue = new TermValue(ipRange, ipRange);
      (this.field as UserTerm).setField("ip_address", termValue);
      return termValue;
    },

    async fetchTermModifier(extractedTerm: TermValue): Promise<any> {
      return this.getTermModifier("ip_address" as UserField, extractedTerm, "In range" as ModifierMatchingType);
    },
  },
  watch: {
    segmentTerm: debounce(async function (val: string) {
      await this.handleSearch("segment", val);
    }, DEBOUNCE_DELAY),
  },
});
