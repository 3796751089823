
import Vue from "vue";
import { isUndefined, isNull, isEmpty, debounce } from "lodash";
import DatePicker from "@/components/Content/DatePicker.vue";
import DataPickerStarTime from "@/components/Content/DataPickerStarTime.vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";
import RadioButton from "@/components/Content/RadioButton.vue";
import { getError } from "@/utils/resolveObjectArray";
import CampaignSummary from "@/components/Content/CampaignSummary.vue";
import {
  isRequired,
  isAfterToday,
  isAfterCompare,
  isMin,
  isMax,
  isSameOrAfterToday,
  adjustDate,
  isMaxCustomLength,
  isBetween,
  hasMaxTwoDecimals,
} from "@/services/rule-services";
import Separator from "@/components/Content/Separator.vue";
import CardActions from "@/components/Content/CardActions.vue";
import { getToday } from "@/services/date-service";
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
import { mapGetters } from "vuex";
import CombineBulletGraph from "@/views/Admin/LineItem/Dependencies/GraphicBidWin/Cards/CombineBullet/index.vue";
import SelectorRadioRetailer from "@/views/Admin/LineItem/Dependencies/Forms/Selectors/Retailer/index.vue";
import { scrollToTop } from "@/utils/services-global";
import { SnackNotificationEntity } from "@/models/Snack";
import { SnactType } from "@/interfaces/snack";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { LineItemList } from "@/interfaces/line_item";
import { FormLineItem } from "@/models/LineItem/Form";
import { DEFAULT_DATE_TIME_FORMAT } from "@/utils/reportData";
import { LineItemRule } from "@/models/LineItem/Rules";
// import { isProd } from "@/services/process-service";

const OPTIMIZATION_BY_CAMPAIGN = "By Campaign";

export default Vue.extend({
  name: "OverviewLineItem",

  props: {
    line_item: {
      type: FormLineItem,
    },
    resources: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: function () {
        return {};
      },
    },
    field_label: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dataGraphic: {
      type: Object,
      default: function () {
        return {};
      },
    },
    canActiveLineItem: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    CardTextField,
    CardAutocomplete,
    CardSwitch,
    DatePicker,
    DataPickerStarTime,
    RadioButton,
    Separator,
    CampaignSummary,
    CardActions,
    ButtonTooltip,
    CombineBulletGraph,
    SelectorRadioRetailer,
    SnackMessage,
  },

  data: () => ({
    title: "OverviewLineItem",
    valid: true,
    open_campaign_summary: false,
    campaign_summary: [],
    is_changed_date: false,
    rules: new LineItemRule(),

    min_date: "",
    max_date: "",

    cols: {
      col_lg_1: 12,
      col_lg_2: 4,
      col_lg_3: 4,
      col_md: 6,
    },

    orderCol: {
      order_Camp: 1,
      order_camp_name: 2,
      order_btn_camp: 3,
      order_adv_id: 4,
      order_adv_name: 5,
      order_allows_events: 6,
      order_radio: 7,
    },

    snack_notification: new SnackNotificationEntity(),
  }),

  created() {},

  mounted() {
    this.$nextTick(async function () {
      await this.configLayout();
      this.resetValidation();
      this.min_date = await this.getDate("start_date");
    });
  },

  computed: {
    ...mapGetters("profile", ["isRolReport", "isAccountRM"]),

    isAccountRetailMedia(): boolean {
      return this.isAccountRM;
    },

    getRetailers(): any[] {
      return this.$store.state.profile.account.retailers;
    },

    isReadOnly(): boolean {
      return this.isRolReport;
    },

    getRules() {
      return {
        isRequired,
        isAfterToday,
        isAfterCompare,
        isSameOrAfterToday,
        isMin,
        isMax,
        budget: [isRequired, isMax(this.line_item.budget, this.resources.selected_campaign?.budget)],
        isMaxCustomLength,
        isBetween,
        hasMaxTwoDecimals,
      };
    },

    getToday() {
      return getToday(DEFAULT_DATE_TIME_FORMAT);
    },

    getMinDate() {
      return this.min_date;
    },

    getMaxDate() {
      return this.max_date;
    },
    isOptimizeCPM() {
      return this.line_item?.strategy_id == 9;
    },
    isOptimizeCPC() {
      return this.line_item?.strategy_id == 10;
    },
    isOptimizeVCR() {
      return this.line_item?.strategy_id == 11;
    },
    isFixBidS() {
      return this.line_item?.bid_strategy_id == 24;
    },
    isAutomatedBigS() {
      return this.line_item?.bid_strategy_id == 25;
    },
    getID() {
      return this.$route.params.id;
    },
    isCreateRoute() {
      return this.$route.path.endsWith("/create");
    },
    isEditRoute() {
      return /^\/.*\/edit\/\d+$/.test(this.$route.path);
    },
    isValidRetailer() {
      return Boolean(this.line_item?.retailer?.id > 0);
    },

    /**
     * STRING RULES
     */

    calcSuggested() {
      if (!this.line_item.line_duration) return -1;
      const calc = Math.ceil(
        Number(this.resources.selected_campaign?.budget_remaining) / Number(this.line_item.line_duration),
      );
      return isFinite(calc) && !isNaN(calc) ? calc : 0;
    },

    getSuggested() {
      if (this.calcSuggested === -1) return "";
      return isNull(this.calcSuggested) ? null : `Suggested ${this.calcSuggested}`;
    },

    getDailyBudgetHint() {
      let isSuggested = this.calcSuggested <= this.line_item.daily_budget;
      return this.getError("daily_budget") || (!isSuggested && this.$t("daily_budget_not_suggested")) || "";
    },

    getErrors() {
      return this.$store.state.proccess.errors;
    },

    routeToCampaign() {
      return `/admin/campaigns/edit/${this.line_item.campaign_id}`;
    },

    getAppendOuterData() {
      if (!this.line_item.campaign_id) return null;
      return {
        icon: "mdi-link",
        text: "Go to Campaign",
        to: this.routeToCampaign,
      };
    },

    getLinePacingItems() {
      if (this.line_item.guaranteed) {
        return this.resources.line_pacings.filter(e => {
          return String(e.value).toLowerCase() === "asap";
        });
      }

      if (this.isEditRoutePacing) {
        return this.resources.line_pacings.filter(e => {
          return String(e.value).toLowerCase() === "lifetime";
        });
      }

      return this.resources.line_pacings;
    },
    getPacingBehaviourItems() {
      return this.resources.pacing_behaviour;
    },
    getCatchupBehaviourItems() {
      return this.resources.catchup_behaviour;
    },

    getStrategyItems() {
      const items: LineItemList[] = this.resources.strategies_filtered;

      if (this.isCampaignPMP) {
        return items.filter(i => i.value.toLowerCase() === "optimized cpm");
      }

      return items;
    },

    getBidStrategyItems() {
      const items: LineItemList[] = this.resources.bid_strategies;

      if ((this.isOptimizationByLine || this.isOptimizationByCampaign) && this.isCampaignPMP) {
        return items.filter(i => i.value.toLowerCase() === "fix");
      }

      if (this.isOptimizeCPC || this.isOptimizeVCR) {
        return items.filter(i => i.value.toLowerCase() === "automated");
      }

      return items;
    },

    getLineItemType() {
      const items: any[] = this.resources.line_item_types;

      if (!this.isCampaignPMP) {
        return items.filter(i => i.value.toLowerCase() !== "audio");
      }

      return items;
    },

    getDataGraphicBidWin() {
      return this.dataGraphic;
    },

    isCampaignPMP() {
      return this.resources.selected_campaign?.campaign_pmp;
    },

    isEditPacing() {
      return this.isOptimizationByLine && this.isCampaignPMP;
    },

    isEditStrategyAndBidStrategy() {
      return (this.isOptimizationByCampaign || this.isOptimizationByLine) && this.isCampaignPMP;
    },

    isOptimizationByLine() {
      return this.resources.selected_campaign?.optimization_strategy?.description !== OPTIMIZATION_BY_CAMPAIGN;
    },

    isOptimizationByCampaign() {
      return this.resources.selected_campaign?.optimization_strategy?.description == OPTIMIZATION_BY_CAMPAIGN;
    },
  },
  methods: {
    async selectRetailer(retailer: any) {
      this.line_item.retailer = retailer;
      this.$emit("change", { key: "retailer", value: retailer });
    },

    async getDate(type: any) {
      return adjustDate(this.resources.selected_campaign[type]);
    },

    fixDecimals() {
      if (this.line_item.multiplier_pacing_behavior) {
        this.line_item.multiplier_pacing_behavior = parseFloat(this.line_item.multiplier_pacing_behavior).toFixed(2);
      }
    },

    async configLayout() {
      if (this.isEditRoute) {
        this.cols.col_lg_1 = 6;
        this.cols.col_lg_2 = 6;
        this.cols.col_lg_3 = 3;
        this.cols.col_md = 4;
        this.orderCol.order_Camp = 3;
        this.orderCol.order_camp_name = 4;
        this.orderCol.order_btn_camp = 2;
        this.orderCol.order_adv_id = 5;
        this.orderCol.order_adv_name = 6;
        this.orderCol.order_radio = 1;
      } else {
        this.cols.col_lg_1 = 12;
        this.cols.col_lg_2 = 4;
        this.cols.col_lg_3 = 4;
        this.cols.col_md = 6;
        this.orderCol.order_Camp = 1;
        this.orderCol.order_camp_name = 2;
        this.orderCol.order_btn_camp = 3;
        this.orderCol.order_adv_id = 4;
        this.orderCol.order_adv_name = 2;
        this.orderCol.order_radio = 6;
      }
    },

    async updateGraphic(value: any) {
      this.$emit("update-graphic", value);
    },

    hasData(attr: any) {
      return !isUndefined(attr) && !isNull(attr) && isEmpty(attr);
    },

    getError(index: any) {
      return getError(this.getErrors, index);
    },

    getColor(active: Boolean) {
      return active ? "text-bold green--text" : "text-bold red--text";
    },

    hasError(index: string | number) {
      return this.errors.hasOwnProperty(index);
    },

    resetValidation() {
      const form = this.$refs.form;
      form?.resetValidation();
    },

    async validate(): Promise<boolean> {
      const form = this.$refs.form;
      return await form?.validate();
    },

    /**
     * Returns Start Date Validations.
     * @param isSubmit On Submit.
     * @param verify Enable date verification if  is same or later than today.
     */
    async addStartDateValidations(isSubmit: boolean = false) {
      this.rules.start_date = [];

      if (!isSubmit) return this.rules.start_date;

      this.rules.start_date.push(this.getRules.isRequired);

      if (this.isEditRoute && this.is_changed_date) {
        this.rules.start_date.push(this.getRules.isSameOrAfterToday(this.line_item?.start_date));
      }

      if (!this.isEditRoute) {
        this.rules.start_date.push(this.getRules.isSameOrAfterToday(this.line_item?.start_date));
      }

      return this.rules.start_date;
    },
    /**
     * Tootip data
     * @param params
     * @returns
     */
    tooltipData(params: any) {
      return {
        icon: "mdi-information-outline",
        text: params,
        to: "",
        right: params?.right ?? true,
      };
    },
    async changeDate(date: any) {
      this.is_changed_date = true;
      await this.addStartDateValidations(true);
    },

    handleChange(event: any, key: any) {
      this.$emit("change", { key: key, value: event });
    },

    handleSelectorChange(payload: { key: string; value: any }) {
      this.$emit("change", payload);
    },

    handleCancel() {
      try {
        this.$emit("handle-cancel");
      } catch (error) {
        console.error("handleCancel", { error: error });
      }
    },

    async applyRules() {
      const { start_date, end_date, budget, fix_cpm, expected_ctr, target_vcr, multiplier_pacing_behavior } =
        this.line_item;

      const selectedCampaign = this.resources.selected_campaign;

      this.rules.addRetailerRules(this.isAccountRetailMedia);
      this.rules.addCommonRules();
      this.rules.addDateRules(start_date, end_date);
      this.rules.addBudgetRules(budget, selectedCampaign?.budget);
      this.rules.addFixCpmRules(fix_cpm);
      this.rules.addFixCtrRules(this.resources.fields.expected_ctr.required, expected_ctr);
      this.rules.addTargetEcpmRules(this.resources.fields.target_ecpm.required);
      this.rules.addTargetVcrRules(this.resources.fields.target_vcr.required, target_vcr);
      this.rules.addMultiplierPacingBehabiourRules(multiplier_pacing_behavior);
      await this.addStartDateValidations(true);
    },

    async isFormValid(): Promise<boolean> {
      const isValid: boolean = await this.validate();

      if (!isValid) {
        this.showNotification(this.$t("validations.has_error_validations"));
        return false;
      }

      if (this.isAccountRetailMedia && !this.isValidRetailer) {
        this.showNotification(this.$t("validations.retailer_id.required"));
        return false;
      }

      return true;
    },

    showNotification(message: string): void {
      this.snack_notification.setSnackData({
        message,
        type: SnactType.ERROR,
      });
    },

    getFetchMethod(): string {
      return this.isEditRoute ? "update" : "create";
    },

    async handleSubmit(params: { continue: Boolean } = { continue: true }) {
      try {
        await this.applyRules();

        if (!(await this.isFormValid())) {
          scrollToTop();
          return;
        }

        const lineItem = new FormLineItem(this.line_item);
        const payload = lineItem.getPayload(this.getFetchMethod());

        this.$emit("save", {
          method: this.getFetchMethod(),
          continue: params.continue,
          data: payload,
        });
      } catch (error) {
        console.error("[Overview::handleSubmit]", { error });
      }
    },

    /**
     * From CardActions - line item
     * @param action
     */
    async handleAction(action: { type: any }) {
      switch (action.type) {
        case "save":
          await this.handleSubmit({ continue: false });
          break;

        case "save-continue":
          await this.handleSubmit({ continue: true });
          break;

        case "cancel":
          this.handleCancel();
          break;
      }
    },

    gotToCampaign() {
      this.$router.push({ name: "LineItemList" });
    },

    getCalculateDuration() {
      if (!this.isValidDates()) return undefined;
      const startDate = this.moment(this.line_item.start_date);
      const endDate = this.moment(this.line_item.end_date);
      let days = this.calculateDuration(startDate, endDate);

      if (days < 0) {
        // this.line_item.end_date = "";
        this.line_item.line_duration = undefined;
        return undefined;
      }

      return days;
    },

    calculateDuration(start: any, end: any) {
      if (!(start.isValid() && end.isValid())) {
        return -1;
      }
      const duration = this.moment.duration(end.diff(start));
      return Math.ceil(duration.asDays());
    },

    isValidDates() {
      const startDate = this.moment(this.line_item.start_date);
      const endDate = this.moment(this.line_item.end_date);
      return startDate.isValid() && endDate.isValid();
    },

    addRowFrecuency() {
      this.$emit("init-frequency-caps");
    },

    deleteRowFrecuency(index: number) {
      if (this.line_item.frequency_caps.length === 0) return;
      this.line_item.frequency_caps.splice(index, 1);
    },

    //Clear Fields
    clearFieldCampaing() {
      this.$emit("clear", "clear-relations");
    },

    // fetching
    async fetchResource(key: any, value?: any) {
      this.$emit(`fetch-resource`, { resource: key, value: value });
    },

    openCampaignSummary(open: Boolean) {
      this.open_campaign_summary = open;
    },

    changeCPMBid(value: any) {
      this.updateCPMBid(value);
    },

    updateCPMBid: debounce(async function (value: any) {
      this.$emit("change", { key: "max_cpm_bid", value: value });
    }, 500),

    handleCampaignChange(value) {
      this.line_item.line_item_type_id = 0;
    },
  },
  watch: {
    async isEdit(val, old) {
      if (val) {
        await this.configLayout();
      }
    },

    async "line_item.guaranteed"(val: boolean) {
      this.$emit("change", { key: "allows_events", value: false });
      if (!val) return;
      this.$emit("change", { key: "line_pacing_id", value: 12 });
    },

    async "line_item.campaign_id"(val, old) {
      if (this.isCreateRoute) {
        this.line_item.guaranteed = false;
      }
      if (isNull(val)) {
        this.$emit("clear", "clear-relations");
      } else {
        this.$emit("change", { key: "campaign_id", value: val });
        await this.fetchResource("campaign_id", val);
      }
    },

    "line_item.line_item_type_id"(val, old) {
      if (val === 22) {
        this.line_item.target_vcr = 1;
      }
      this.$emit("change", { key: "line_item_type_id", value: val });
    },

    async "resources.selected_campaign.start_date"(val, old) {
      this.min_date = await this.getDate("start_date");
    },

    async "resources.selected_campaign.end_date"(val, old) {
      this.max_date = await this.getDate("end_date");
    },

    "line_item.strategy_id"(val, old) {
      this.$emit("change", { key: "strategy_id", value: val });
    },

    "line_item.bid_strategy_id"(val, old) {
      this.$emit("change", { key: "bid_strategy_id", value: val });
    },

    "line_item.budget_type_id"(val, old) {
      this.$emit("change", { key: "budget_type_id", value: val });
    },

    "line_item.line_pacing_id"(val, old) {
      this.$emit("change", { key: "line_pacing_id", value: val });
    },

    "line_item.multiplier_pacing_behavior"(val, old) {
      this.$emit("change", { key: "multiplier_pacing_behavior", value: val });
    },
    "line_item.pacing_behavior_id"(val, old) {
      this.$emit("change", { key: "pacing_behavior_id", value: val });
    },
    "line_item.catchup_behavior_id"(val, old) {
      this.$emit("change", { key: "catchup_behavior_id", value: val });
    },

    "line_item.target_ecpc"(val, old) {
      this.$emit("change", { key: "target_ecpc", value: val });
    },

    "line_item.expected_ctr"(val, old) {
      //.$emit("change", { key: "expected_ctr", value: val });
    },

    "line_item.max_cpm_bid"(val, old) {
      this.$emit("change", { key: "max_cpm_bid", value: val });
    },

    async "line_item.start_date"(val, old) {
      this.$emit("change", { key: "line_duration", value: undefined });
    },

    async "line_item.end_date"(val, old) {
      this.$emit("change", { key: "line_duration", value: undefined });
    },

    // "line_item.retailer_id": {
    //   handler(val: number | undefined) {
    //     if (!isProd()) {
    //       console.debug(`[DEBUG:: watch::retailer_id] id: ${val}`);
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
});
