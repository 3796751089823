import Vue from "vue";
import { isNull } from "lodash";
import { mapActions, mapGetters } from "vuex";
import {
  ComponentType,
  configModifier,
  ConfigModifierTerm,
  DeliverModifierField,
} from "@/models/Modifiers/Delivery/term";
import HeaderTableDelivery from "@/views/Admin/Modifiers/List/HeaderTableDelivery.vue";
import SelectorAutocompleteWithSearcher from "@/views/Admin/Modifiers/Forms/SelectorAutocompleteWithSearcher/index.vue";
import SelectorAutocomplete from "@/views/Admin/Modifiers/Forms/SelectorAutocomplete/index.vue";
import SelectorTextField from "@/views/Admin/Modifiers/Forms/SelectorTextField/index.vue";
import SelectorRadioButton from "@/views/Admin/Modifiers/Forms/SelectorRadioButton/index.vue";
import CardTextField from "@/components/Content/CardTextField.vue";

const excludesColums = ["rank", "weight", "porcentaje", "delete"];

export default Vue.extend({
  name: "TableDelivery",
  props: {
    headers: {
      type: Array,
      default: [],
    },
    items: {
      type: Array,
      default: [],
    },
    fallback_weight: {
      type: String,
      default: "1",
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderTableDelivery,
    CardTextField,
    SelectorAutocompleteWithSearcher,
    SelectorAutocomplete,
    SelectorTextField,
    SelectorRadioButton,
  },
  data: () => ({}),
  created() {},
  mounted() {
    this.$nextTick(async () => {});
  },
  computed: {
    ...mapGetters("modifier_options", ["getModifierOption"]),

    getItems() {
      return this.items;
    },

    getTargetItems() {
      return this.items.map(item => {
        item.targeting.forEach(async t => {
          item[t.key] = t;
        });
        return this.calculatePercentage(item);
      });
    },

    computedFallback: {
      get() {
        return this.fallback_weight;
      },
      set(value) {
        this.$emit("fallback_change", value);
      },
    },
    getFallback() {
      return {
        percent: ((parseFloat(this.computedFallback) * 100) / this.total).toFixed(2),
      };
    },
    total() {
      return this.items
        .reduce((accum, item) => accum + parseFloat(item.weight), parseFloat(this.computedFallback) || 0)
        .toFixed(2);
    },
    getHeaderTable() {
      return this.headers.map(h => {
        let item = {};
        item["text"] = h.text;
        item["value"] = h.value;
        item["component"] = !excludesColums.includes(h.value);
        return item;
      });
    },
    getAllTerms(): ConfigModifierTerm[] {
      return configModifier.terms;
    },
  },
  methods: {
    ...mapActions("proccess", ["setLoadingField"]),
    ...mapActions("loading", ["setLoadingData"]),

    getTermsByComponentType(type: ComponentType): ConfigModifierTerm[] {
      return configModifier.terms.filter(t => t.componentType === type);
    },

    getAllConfigTerms(): ConfigModifierTerm[] {
      return configModifier.terms;
    },

    /**
     * Actualizar item seleccionado en la data de `targeting`
     * @param key
     * @param model
     */
    updateTargetItems(key: DeliverModifierField, model: any) {
      this.items.map((i: any) => {
        let target = i.targeting.find((t: any) => t.key === key);
        if (target) {
          target = model;
        }
      });
    },

    /**
     * Observa el event: change para ejecutarse.
     * @param event
     */
    handleAutocompleteChange(event: { key: DeliverModifierField; model: any }) {
      try {
        this.updateTargetItems(event.key, event.model);
      } catch (error) {
        console.error(`[TableDeliveryLogic::handleAutocompleteChange] key:  ${event.key}`, error);
      }
    },

    getConfig(target: DeliverModifierField) {
      return configModifier.getConfig(target);
    },

    async handleRemoveColumn(column) {
      this.$emit("delete-column", column.value);
    },

    async handleRemoveItem(item) {
      this.$emit("delete-item", item);
    },

    calculatePercentage(item: any) {
      let result = (item.weight * 100) / this.total;

      if (isNaN(result)) {
        item["porcentaje"] = 100;
        return result;
      }

      item["porcentaje"] = result.toFixed(2);

      return item;
    },
  },
  watch: {},
});
