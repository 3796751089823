
import Vue from "vue";
import { mapActions } from "vuex";
import { getError } from "@/utils/resolveObjectArray";
import { isRequired } from "@/services/rule-services";
import { initModifierTerm } from "@/utils/initData";
import { filter, find, isArray, isNull, isUndefined } from "lodash";
import Time from "@/views/Admin/LineItem/Dependencies/Targetings/time.vue";
import { TypeLoading } from "@/interfaces/loading";
import InitModifierOption from "@/models/InitModifierOption";
import { TermValue, TimeResource, TimeTerm } from "@/models/Modifiers/Terms/time";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "In range";
// Configs Targeting Predicates
const EXCLUDED = "None";
const INCLUDED = "All";

export default Vue.extend({
  name: "TimeModule",
  props: {
    module: {
      type: Object,
      default: function () {
        return {};
      },
    },
    matching_types: {
      type: Array,
      default() {
        return [];
      },
    },
    modifiers_options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    isDeliveryModifierType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Time,
  },
  data: () => ({
    valid: false,
    entity: new InitModifierOption(),
    selectedTime: new TimeResource(),
    time: new TimeTerm({ user_time_of_week: new TermValue() }),
    targeting_predicates: [],
    weekdays: [],
  }),
  async created() {
    this.setLoadingData(TypeLoading.loading);
    this.targeting_predicates = await this.dispatchTargetingPredicates();
    await this.setLoadingData();
  },
  async mounted() {
    this.weekdays = [
      {
        key: 1,
        value: this.$t("week-days.monday.abbreviation"),
      },
      {
        key: 2,
        value: this.$t("week-days.tuesday.abbreviation"),
      },
      {
        key: 3,
        value: this.$t("week-days.wednesday.abbreviation"),
      },
      {
        key: 4,
        value: this.$t("week-days.thursday.abbreviation"),
      },
      {
        key: 5,
        value: this.$t("week-days.friday.abbreviation"),
      },
      {
        key: 6,
        value: this.$t("week-days.saturday.abbreviation"),
      },
      {
        key: 0,
        value: this.$t("week-days.sunday.abbreviation"),
      },
    ];
    this.$nextTick(async () => {
      await this.getTermTime();
    });
  },
  computed: {
    getErrors() {
      return this.$store.state.proccess.errors;
    },

    getRules() {
      return {
        isRequired,
      };
    },

    getPredicateIds() {
      return {
        INCLUDED: this.isIncluded,
        EXCLUDED: this.isExcluded,
      };
    },

    isExcluded() {
      const result = find(this.targeting_predicates, {
        value: EXCLUDED,
      });

      return parseInt(result?.id);
    },

    isIncluded() {
      const result = find(this.targeting_predicates, {
        value: INCLUDED,
      });

      return parseInt(result?.id);
    },

    getMatchingTypes() {
      return this.matching_types;
    },

    getMatchingTypeId(): Number {
      return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
    },
  },
  methods: {
    ...mapActions("loading", ["setLoadingData"]),

    getError(index: string) {
      return getError(this.getErrors, index);
    },

    updateSelectedChecked(params: {
      tab: any;
      key: any;
      timeSelected: any;
      weekDay: any;
      targeting_predicate_id: Number;
    }) {
      const finded = find(this.time.user_time_of_week.targeting_terms, {
        value: String(params.timeSelected.text),
      });

      if (!isUndefined(finded)) {
        this.time.user_time_of_week.targeting_terms = filter(this.time.user_time_of_week.targeting_terms, function (s) {
          return s.value != String(params.timeSelected.text);
        });
        this.handleRemoveTermTime(String(params.timeSelected.text));
      } else {
        let entity = {
          value: String(params.timeSelected.text),
          value_name: String(params.weekDay) + " - " + String(params.timeSelected.display),
          targeting_key_id: this.time.user_time_of_week.targeting_key_id,
          targeting_predicate_id: params.targeting_predicate_id,
          override_multiplier: this.isOverride("user_time_of_week"),
        };
        this.time.user_time_of_week.targeting_terms.push(entity);
        this.handleChangeSelection(entity);
      }
    },

    updateSelectedPredicate(params: { tab: any; key: any; value: any }) {
      this.time.user_time_of_week.targeting_terms.forEach((t: { targeting_predicate_id: any }) => {
        t.targeting_predicate_id = params.value;
      });
    },

    handleChangeSelection(props: any) {
      this.$emit("add-term", this.getTermModifier(props));
    },

    async dispatchTargetingPredicates() {
      return this.$store.dispatch("targeting/getTargetingPredicates");
    },

    getValueName(value: any) {
      if (isUndefined(value) || isNull(value)) {
        return undefined;
      }
      let props = value.split(",");
      if (!isArray(props) || props.length != 2) {
        return undefined;
      }
      let label = undefined;
      let day: any = undefined;

      if (props[1] < 1440) {
        // lunes
        day = this.weekdays.find((d: any) => {
          return d.key == 1;
        });
      } else if (props[0] >= 1440 && props[1] < 2880) {
        // Martes
        day = this.weekdays.find((d: any) => {
          return d.key == 2;
        });
      } else if (props[0] >= 2880 && props[1] < 4320) {
        // Miércoles
        day = this.weekdays.find((d: any) => {
          return d.key == 3;
        });
      } else if (props[0] >= 4320 && props[1] < 5760) {
        // Jueves
        day = this.weekdays.find((d: any) => {
          return d.key == 4;
        });
      } else if (props[0] >= 5760 && props[1] < 7200) {
        // Viernes
        day = this.weekdays.find((d: any) => {
          return d.key == 5;
        });
      } else if (props[0] >= 7200 && props[1] < 8640) {
        // Sábado
        day = this.weekdays.find((d: any) => {
          return d.key == 6;
        });
      } else if (props[0] >= 8640 && props[1] < 10080) {
        // Domingo
        day = this.weekdays.find((d: any) => {
          return d.key == 0;
        });
      } else {
        return label;
      }
      if (isUndefined(day) || isNull(day)) {
        return label;
      }
      let h = props[0] != 0 ? props[0] / 60 : 0;
      let m = (props[1] + 1) / 60 - 1;
      label = day.value.concat(" ").concat(h).concat(":").concat(m);
      return label;
    },

    getTermModifier(props: any) {
      let entity: any = initModifierTerm();
      entity.key = "user_time_of_week";
      entity.value = props.value;
      entity.value_name = props.value_name;
      entity.module_id = this.module?.id;
      entity.module_name = this.module?.value;
      entity.matching_type_id = this.getMatchingTypeId;
      entity.matching_type_name = MATCHING_TYPE_DEFAULT;
      return entity;
    },

    handleRemoveTermTime(value: any) {
      this.$emit("remove-term-time", value);
    },

    removeTermTime(item: any) {
      this.time.user_time_of_week.targeting_terms = this.time.user_time_of_week.targeting_terms.filter(m => {
        return m.value != item.value;
      });
    },

    isOverride(key: any) {
      const modifierOption = this.modifiers_options[key];
      const hasOverrideMultiplier: boolean = !isUndefined(modifierOption?.override_multiplier);
      return hasOverrideMultiplier ? (modifierOption.override_multiplier == "true" ? 1 : 0) : undefined;
    },

    async getTermTime() {
      this.$emit("get-term", "user_time_of_week");
    },

    setTerm(valuesTerm: any) {
      valuesTerm.forEach(v => {
        let entity = {
          value: String(v.value),
          value_name: v.value_name,
          targeting_key_id: this.time.user_time_of_week.targeting_key_id,
        };
        this.time.user_time_of_week.targeting_terms.push(entity);
      });
    },
  },
  watch: {},
});
