export class FrequencyCap {
  id?: number;
  every_time: number | null;
  impressions: number | null;
  type?: string | null;
  type_id?: number | null;
  unit_time_id: number | null;

  constructor(payload?: Partial<FrequencyCap>) {
    this.id = payload?.id;
    this.every_time = payload?.every_time ?? null;
    this.impressions = payload?.impressions ?? null;
    this.type = payload?.type ?? null;
    this.type_id = payload?.type_id ?? null;
    this.unit_time_id = payload?.unit_time_id ?? null;
  }
}

export class FormLineItem {
  id?: number;
  external_id?: number;
  account_id?: number;
  advertiser_id: number | null = null;
  campaign_id: number | null = null;
  campaign: any | null = null;
  name: string = "";
  budget_type_id: number | null = null;
  budget: string = "";
  daily_budget: string = "";
  daily_budget_suggested: string = "";
  start_date: Date | null = null;
  end_date: Date | null = null;
  active: boolean = true;
  alternative_id: string = "";
  bid_strategy_id: number | null = null;
  strategy_id: number | null = null;
  line_pacing_id: number | null = null;
  line_item_type_id: number | null = null;
  bid_shading_id: number | null = null;
  modifier_id: number | null = null;
  delivery_modifier_id: number | null = null;
  creative_method_id: number | null = null;
  fix_cpm: number | null = null;
  cpm_bid: number | null = null;
  target_ecpm: number | null = null;
  target_ecpc: number | null = null;
  target_ctr: number | null = null;
  target_ecpcv: number | null = null;
  target_cpcv: number | null = null;
  target_vcr: number | null = null;
  max_cpm_bid: number | null = null;
  pacing_behavior_id: number | null = null;
  catchup_behavior_id: number | null = null;
  multiplier_pacing_behavior: number = 1.0;
  expected_ctr: number | null = null;
  frequency_caps: FrequencyCap[] = [];
  creative_associations: any[] = [];
  timezones: any[] = [];
  events: any[] = [];

  allows_events: boolean = false;
  guaranteed: boolean = false;

  line_duration?: number;

  retailer?: { id: number; name: string };
  retailer_id: number | null = null;

  constructor(payload?: Partial<FormLineItem>) {
    this.id = payload?.id;
    this.external_id = payload?.external_id;
    this.account_id = payload?.account_id;
    this.advertiser_id = payload?.advertiser_id ?? null;
    this.campaign_id = payload?.campaign_id ?? null;
    this.campaign = payload?.campaign ?? null;
    this.name = payload?.name ?? "";
    this.budget_type_id = payload?.budget_type_id ?? null;
    this.budget = payload?.budget ?? "";
    this.daily_budget = payload?.daily_budget ?? "";
    this.daily_budget_suggested = payload?.daily_budget_suggested ?? "";
    this.start_date = payload?.start_date ?? null;
    this.end_date = payload?.end_date ?? null;
    this.alternative_id = payload?.alternative_id ?? "";
    this.bid_strategy_id = payload?.bid_strategy_id ?? null;
    this.strategy_id = payload?.strategy_id ?? null;
    this.line_pacing_id = payload?.line_pacing_id ?? null;
    this.line_item_type_id = payload?.line_item_type_id ?? null;
    this.bid_shading_id = payload?.bid_shading_id ?? null;
    this.modifier_id = payload?.modifier_id ?? null;
    this.delivery_modifier_id = payload?.delivery_modifier_id ?? null;
    this.creative_method_id = payload?.creative_method_id ?? null;
    this.fix_cpm = payload?.fix_cpm ?? null;
    this.cpm_bid = payload?.cpm_bid ?? null;
    this.target_ecpm = payload?.target_ecpm ?? null;
    this.target_ecpc = payload?.target_ecpc ?? null;
    this.target_ctr = payload?.target_ctr ?? null;
    this.target_ecpcv = payload?.target_ecpcv ?? null;
    this.target_cpcv = payload?.target_cpcv ?? null;
    this.target_vcr = payload?.target_vcr ?? null;
    this.max_cpm_bid = payload?.max_cpm_bid ?? null;
    this.pacing_behavior_id = payload?.pacing_behavior_id ?? null;
    this.catchup_behavior_id = payload?.catchup_behavior_id ?? null;
    this.multiplier_pacing_behavior = payload?.multiplier_pacing_behavior ?? 1.0;
    this.expected_ctr = payload?.expected_ctr ?? null;
    this.frequency_caps = payload?.frequency_caps?.map(f => new FrequencyCap(f)) ?? [];
    this.creative_associations = payload?.creative_associations ?? [];
    this.timezones = payload?.timezones ?? [];
    this.events = payload?.events ?? [];
    this.allows_events = payload?.allows_events ?? false;
    this.line_duration = payload?.line_duration;
    this.retailer = payload?.retailer;
    this.retailer_id = payload?.retailer_id ?? null;
    this.active = Boolean(payload?.active);
    this.guaranteed = payload?.guaranteed ?? false;
  }

  formatDate(date: Date | null): string {
    if (!date) return "";
    const newDate = new Date(date);
    const pad = (num: number) => num.toString().padStart(2, "0");
    return (
      `${newDate.getFullYear()}-${pad(newDate.getMonth() + 1)}-${pad(newDate.getDate())} ` +
      `${pad(newDate.getHours())}:${pad(newDate.getMinutes())}:${pad(newDate.getSeconds())}`
    );
  }

  toBasePayload(): FormPayload {
    return new FormPayload({
      allows_events: this.allows_events ?? false,
      advertiser_id: this.advertiser_id,
      retailer_id: this.retailer?.id,
      campaign_id: this.campaign_id,
      name: this.name,
      budget: this.budget,
      daily_budget: this.daily_budget,
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      active: this.active ? 1 : 0,
      alternative_id: this.alternative_id,
      bid_strategy_id: this.bid_strategy_id,
      strategy_id: this.strategy_id,
      line_pacing_id: this.line_pacing_id,
      line_item_type_id: this.line_item_type_id,
      pacing_behavior_id: this.pacing_behavior_id,
      multiplier_pacing_behavior: Number(this.multiplier_pacing_behavior ?? 1),
      catchup_behavior_id: this.catchup_behavior_id,
      creative_method_id: this.creative_method_id,
      fix_cpm: this.fix_cpm,
      cpm_bid: this.cpm_bid,
      target_ecpc: this.target_ecpc,
      expected_ctr: this.expected_ctr,
      target_vcr: this.target_vcr,
      max_cpm_bid: this.max_cpm_bid,
      frequency_caps: this.frequency_caps?.map(f => new FrequencyCap(f)) ?? [],
      guaranteed: this.guaranteed,
    });
  }

  toPayloadCreate(): FormPayload {
    return this.toBasePayload();
  }

  toPayloadUpdate(): FormPayload {
    return new FormPayload({
      id: this.id,
      ...this.toBasePayload(),
    });
  }

  getPayload(type: "create" | "update") {
    const payloadMap = {
      create: this.toPayloadCreate.bind(this),
      update: this.toPayloadUpdate.bind(this),
    };
    return payloadMap[type]();
  }
}

export class FormPayload {
  id?: number;
  allows_events: boolean = false;
  advertiser_id: number | null = null;
  retailer_id?: number;
  campaign_id: number | null = null;
  name: string = "";
  budget: string = "";
  daily_budget: string = "";
  start_date: string = "";
  end_date: string = "";
  alternative_id: string = "";
  bid_strategy_id: number | null = null;
  strategy_id: number | null = null;
  line_pacing_id: number | null = null;
  line_item_type_id: number | null = null;
  pacing_behavior_id: number | null = null;
  multiplier_pacing_behavior: number = 1.0;
  catchup_behavior_id: number | null = null;
  creative_method_id: number | null = null;
  fix_cpm: number | null = null;
  cpm_bid: number | null = null;
  target_ecpc: number | null = null;
  expected_ctr: number | null = null;
  target_vcr: number | null = null;
  max_cpm_bid: number | null = null;
  frequency_caps: FrequencyCap[] = [];
  active: 0 | 1 = 0;
  guaranteed: boolean = false;

  constructor(payload?: Partial<FormPayload>) {
    if (payload) {
      Object.assign(this, payload);
      this.normalizeValues(payload);
      this.parseFrequencyCaps(payload.frequency_caps);
    }
  }

  private parseFrequencyCaps(frequencyCaps: FrequencyCap[] = []) {
    this.frequency_caps = frequencyCaps.map(f => new FrequencyCap(f));
  }

  private normalizeValues(payload?: Partial<FormPayload>) {
    if (!payload) return;

    this.expected_ctr = this.normalizePercentage(this.expected_ctr);
    this.target_vcr = this.normalizePercentage(this.target_vcr);
    this.multiplier_pacing_behavior = payload.multiplier_pacing_behavior
      ? parseFloat(payload.multiplier_pacing_behavior.toString())
      : 1.0;
  }

  private normalizePercentage(value: number | null): number | null {
    return value ? value / 100 : 0;
  }
}

export interface ResourceFrequecyCap {
  id?: number;
  value?: string;
  extra?: number;
}
